/** @jsxImportSource @emotion/react */
import Image from "next/image"
import React, { FC } from "react"
import { Token } from "types"

import { useStyles } from "./styles"

export interface TokenIconProps {
  token: Pick<Token, "asset" | "symbol"> | Token
  className?: string
}

export const TokenIcon: FC<TokenIconProps> = ({ className, token }) => {
  const styles = useStyles()

  return <Image src={token.asset} css={styles.icon} alt={token.symbol} className={className} />
}
