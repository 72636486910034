/** @jsxImportSource @emotion/react */
import Typography from "@mui/material/Typography"
import React, { FC } from "react"
import { Token } from "types"

import { TypographyVariant } from "muiTheme"

import { TokenIcon } from "../TokenIcon"
import { useStyles } from "./styles"

export interface TokenIconWithSymbolProps {
  token: Token
  useName?: boolean
  className?: string
  variant?: TypographyVariant
}

export const TokenIconWithSymbol: FC<TokenIconWithSymbolProps> = ({ className, token, useName, variant }) => {
  const styles = useStyles()

  return (
    <div className={className} css={styles.container}>
      <TokenIcon token={token} css={styles.icon} />

      <Typography component="span" variant={variant}>
        {useName ? token.name : token.symbol}
      </Typography>
    </div>
  )
}
