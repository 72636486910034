import { css } from "@emotion/react"
import { useTheme } from "@mui/material"

export const useStyles = () => {
  const theme = useTheme()

  return {
    theme,
    container: css`
      color: ${theme.palette.text.primary};
      padding-left: ${theme.spacing(5)};
      padding-right: ${theme.spacing(5)};
    `,
  }
}
