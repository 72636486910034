import { css } from "@emotion/react"
import { darken, useTheme } from "@mui/material"
import { borderRadius } from "muiTheme"

export const useStyles = () => {
  const theme = useTheme()
  return css`
    .MuiTooltip-popper {
      .MuiTooltip-tooltip {
        border-radius: ${borderRadius.small}px;
        background-color: ${theme.palette.background.paper};
        font-size: ${theme.typography.small2.fontSize};
        font-weight: ${theme.typography.small2.fontWeight};
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(2)};
        border: 1px solid ${theme.palette.divider};
        line-height: 1.4;
      }
      .MuiTooltip-arrow {
        display: none;
      }
    }
  `
}
