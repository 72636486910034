import { Typography, Stack } from "@mui/material"
import { Button, ButtonProps, TokenIcon } from "components"
import React from "react"
import { Token } from "types"
import { addRawTokenToWallet } from "utilities/misc"

type AddTokenToWalletButtonProps = ButtonProps & {
  token: Token
}

const AddTokenToWalletButton = ({ token, ...buttonProps }: AddTokenToWalletButtonProps) => {
  return (
    <Button small variant="secondary" onClick={() => addRawTokenToWallet(token)} {...buttonProps}>
      <Typography variant="body1">
        <Stack direction="row" alignItems="center" spacing={1}>
          <span>Add</span>
          <TokenIcon token={token} />
          <span>{token.symbol}</span>
          <span>to wallet</span>
        </Stack>
      </Typography>
    </Button>
  )
}

export default AddTokenToWalletButton
