import config from "config"
import noop from "noop-ts"
import React, { FC, createContext, useEffect, useState } from "react"
import { useTranslation } from "translation"

import { fetchIpComplianceStatus, fetchWalletComplianceStatus } from "utilities/compliance"
import { toast } from "components/Toast"
import { LS_KEY_ACCEPTED_TERMS } from "../constants"
import { Chain, Connector, useAccount, useConnect, useDisconnect, useNetwork, useSwitchNetwork, Address } from "wagmi"
import { useConnectModal } from "@rainbow-me/rainbowkit"
import { Modal } from "components"
import { Typography } from "@mui/material"
import Compliance from "components/Compliance"

export interface AuthContextValue {
  address: Address | undefined
  connector: Connector<any, any> | undefined
  chain: Chain | undefined
  chains: Chain[]
  logIn: () => Promise<void>
  logOut: () => Promise<void>
  switchNetwork: ((chainId_?: number) => void) | undefined
  isAuthLoading: boolean
}

export const AuthContext = createContext<AuthContextValue>({
  address: undefined,
  connector: undefined,
  chain: undefined,
  chains: [],
  logIn: noop,
  logOut: noop,
  switchNetwork: noop,
  isAuthLoading: false,
})

export const AuthProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation()

  // Wagmi Hooks
  const { address: _address, connector } = useAccount()
  const { disconnect } = useDisconnect()
  const { chain, chains } = useNetwork()
  const { switchNetwork } = useSwitchNetwork()

  // The below is needed to prevent hydration errors so that the address is only set once mounted
  // For more info: https://ethereum.stackexchange.com/questions/133612/error-hydration-failed-because-the-initial-ui-does-not-match-what-was-rendered
  const [address, setAddress] = useState<Address | undefined>(undefined)
  useEffect(() => {
    setAddress(_address)
  }, [_address])

  // Compliance modal state
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)

  // RainbowKit hooks
  const { openConnectModal } = useConnectModal()

  // Terms of use
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(
    () => typeof window !== "undefined" && window.localStorage.getItem(LS_KEY_ACCEPTED_TERMS) === "true",
  )
  const acceptTerms = () => {
    window.localStorage.setItem(LS_KEY_ACCEPTED_TERMS, "true")
    setAcceptedTerms(true)
    setIsAuthModalOpen(false)
    openConnectModal?.()
  }

  const [isRestricted, setIsRestricted] = useState<boolean>(false)

  const [isAuthLoading, setIsAuthLoading] = useState(false)

  const logIn = async () => {
    setIsAuthLoading(true)
    // Check IP compliance
    const ipComplianceStatus = await fetchIpComplianceStatus()
    setIsRestricted(ipComplianceStatus.isRestricted)
    if (ipComplianceStatus.isRestricted) {
      toast.error({ message: ipComplianceStatus.reason })
      return
    }
    // If already accepted terms, then show connect modal, otherwise show terms modal
    if (acceptedTerms) {
      openConnectModal?.()
    } else {
      setIsAuthModalOpen(true)
    }
    setIsAuthLoading(false)
  }

  const logOut = async () => {
    disconnect()
  }

  useEffect(() => {
    if (!address) {
      return
    }
    // Check wallet compliance
    fetchWalletComplianceStatus(address).then((walletComplianceStatus) => {
      if (walletComplianceStatus.isRestricted) {
        toast.error({
          message: "Your wallet is associated with suspect activities. You cannot use this app with this wallet.",
        })
        logOut()
      }
    })
  }, [address])

  return (
    <AuthContext.Provider
      value={{
        address,
        connector,
        chain,
        chains,
        logIn,
        logOut,
        switchNetwork,
        isAuthLoading,
      }}
    >
      <Modal
        className="flux-modal"
        isOpen={isAuthModalOpen}
        handleClose={() => setIsAuthModalOpen(false)}
        title={<Typography variant="h6">{t("authModal.title.compliance")}</Typography>}
      >
        <Compliance isRestricted={isRestricted} onAccept={acceptTerms} />
      </Modal>
      {children}
    </AuthContext.Provider>
  )
}
