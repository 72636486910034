/** @jsxImportSource @emotion/react */
import { FC, useContext } from "react"
import { useTranslation } from "translation"
import { Button, ButtonProps } from "../../Button"
import { AuthContext } from "context/AuthContext"
import { ConnectButton as RainbowKitConnectButton } from "@rainbow-me/rainbowkit"
import { css } from "@emotion/react"

export interface ConnectButtonProps extends ButtonProps {
  accountAddress?: string
}

const ConnectButton: FC<ButtonProps> = (props) => {
  const { t } = useTranslation()
  const { logIn, address, isAuthLoading } = useContext(AuthContext)
  return (
    <div
      // Disable RainbowKit hover effect
      css={css`
        :hover button {
          transform: none !important;
        }
      `}
    >
      {address ? (
        <RainbowKitConnectButton />
      ) : (
        <Button loading={isAuthLoading} id="connect-button" onClick={logIn} {...props}>
          {t("connectButton.title")}
        </Button>
      )}
    </div>
  )
}

export default ConnectButton
