import { css } from "@emotion/react"
import { useTheme } from "@mui/material"
import { borderRadius } from "muiTheme"

export const useStyles = () => {
  const theme = useTheme()
  return {
    spacer: css`
      width: ${theme.shape.drawerWidthDesktop};

      ${theme.breakpoints.down("lg")} {
        display: none;
      }
    `,
    drawer: css`
      width: ${theme.shape.drawerWidthDesktop};
      background: transparent;
      height: 100vh;
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      bottom: 0;

      > .MuiDrawer-paper {
        border-right: none;
        background: transparent;
      }

      ${theme.breakpoints.down("lg")} {
        display: none;
      }
    `,
    drawerContent: css`
      width: ${theme.shape.drawerWidthDesktop};
      ${theme.breakpoints.down("lg")} {
        width: ${theme.shape.drawerWidthTablet};
      }
      display: block;
      z-index: 1000;
    `,
    headerLink: css`
      cursor: pointer;
      display: block;
    `,
    toolbar: css`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: ${theme.spacing(4)};
      padding: ${theme.spacing(5, 0, 3)};
      min-height: 0px !important;

      ${theme.breakpoints.down("lg")} {
        min-height: initial;
      }
      ${theme.breakpoints.down("lg")} {
        padding-left: initial;
        padding-right: initial;
      }
      .MuiButton-text {
        padding: 0;
        min-width: 0;
      }
      ${theme.breakpoints.down("lg")} {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    `,
    mobileList: css`
      margin-top: ${theme.spacing(5)};
      padding: 0;
      height: 100%;
    `,
    desktopList: css`
      margin: ${theme.spacing(2, 0, 2, 2)};
      padding: 0;
    `,
    listItem: css`
      transition: color 0.3s;
      padding: 0;
      border-radius: ${borderRadius.medium}px;

      :hover {
        background: inherit;
      }
      :hover > a {
        background: ${theme.palette.action.hover};
        opacity: 1;
      }

      a {
        padding: ${theme.spacing(2, 6, 2, 2.5)};
        opacity: 0.8;
        display: inline-flex;
        justify-content: start;
        align-items: center;
        width: 100%;
        border-radius: ${borderRadius.medium}px;

        ${theme.breakpoints.down("lg")} {
          width: auto;
          margin: auto;
          border-radius: ${borderRadius.medium}px;
          padding: ${theme.spacing(3, 4)};
          justify-content: center;
        }

        ${theme.breakpoints.down("lg")} {
          border-radius: 0;
        }
        .icon {
          opacity: 0.5;
          color: ${theme.palette.action.active};
        }
        :hover {
          .icon,
          p,
          span {
            opacity: 1;
            color: ${theme.palette.text.primary};
          }
        }
      }

      .active-menu-item,
      .active-menu-item:hover {
        .left-border {
          border-radius: 0 ${borderRadius.small}px ${borderRadius.small}px 0;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;

          ${theme.breakpoints.down("lg")} {
            display: none;
          }
        }
        .icon,
        p,
        span {
          opacity: 1;
          color: ${theme.palette.text.primary};
        }
      }
    `,
    listItemIcon: css`
      min-width: ${theme.spacing(7)};
      ${theme.breakpoints.down("lg")} {
        min-width: 0;
        justify-content: center;
      }
      color: inherit;
      .icon {
        height: 1.4rem;
        width: 1.4rem;
      }
    `,
    listItemText: css`
      display: block;
      ${theme.breakpoints.down("lg")} {
        display: none;
      }
      ${theme.breakpoints.down("lg")} {
        display: block;
      }
    `,
    socialButton: css`
      min-width: auto;
      border-radius: ${borderRadius.medium}px;
      opacity: 0.6;
      :hover {
        opacity: 1;
      }
    `,

    listItemNewBadge: css`
      border: 1px solid ${theme.palette.interactive.success};
      background-color: ${theme.palette.interactive.success10};
      border-radius: ${theme.spacing(1)};
      height: ${theme.spacing(5)};
      display: flex;
      align-items: center;
      padding: ${theme.spacing(0, 1)};
      margin-left: ${theme.spacing(3)};

      ${theme.breakpoints.down("lg")} {
        display: none;
      }
    `,
    listItemNewBadgeText: css`
      color: ${theme.palette.interactive.success};
      margin-top: 1px;
    `,
    logo: css`
      display: block;
      ${theme.breakpoints.down("lg")} {
        display: none;
      }
      height: 40px;
      width: auto;
      ${theme.breakpoints.down("lg")} {
        display: none;
      }
    `,
    logoClosed: css`
      display: none;
      ${theme.breakpoints.down("lg")} {
        display: block;
      }
    `,
    connectButton: css`
      width: 100%;
    `,
    connectContainer: css`
      padding: ${theme.spacing(0, 4)};
      width: 100%;
    `,
    mobileMenuBox: css`
      display: none;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: ${theme.spacing(2, 3, 0, 3)};

      ${theme.breakpoints.down("lg")} {
        display: flex;
      }
    `,
    mobileLogo: css`
      flex-shrink: 0;
      height: 26px;
      width: 26px;
      margin-top: ${theme.spacing(0.5)};
      margin-bottom: ${theme.spacing(-1)};
    `,
    burger: css`
      height: 18px;
      width: 18px;
    `,
    mobileMenu: css`
      .MuiMenu-list {
        display: flex;
        flex-direction: column;
        padding: ${theme.spacing(2, 3)};
      }

      > .MuiPaper-root {
        background-color: ${theme.palette.background.default};
        height: 100vh;
        max-height: 100vh;
        width: 100vw;
        max-width: 100vw;
        border-radius: 0;
        border: 0;
        box-shadow: none;
        padding: 0;
        top: 0 !important;
      }
    `,
    mobileListItemText: css``,
    mobileListItem: css`
      a {
        display: flex;
        flex-direction: row;
        flex: 1;
        width: 100%;
        padding-top: ${theme.spacing(2)};
        padding-bottom: ${theme.spacing(2)};
        justify-content: space-between;
        padding-left: ${theme.spacing(2)};
        padding-right: ${theme.spacing(2)};
        border-radius: ${borderRadius.medium}px;
      }
      :hover {
      }
      .active-mobile-menu-item {
        border-radius: ${borderRadius.medium}px;
        color: ${theme.palette.background.default};

        span,
        .icon {
          opacity: 1;
          color: ${theme.palette.background.default};
        }
      }
    `,

    mobileArrow: css`
      height: 26px;
      width: 26px;
    `,
    mobileLabel: css`
      flex-direction: row;
      justify-content: center;
      display: inline-flex;
      align-items: center;
      .icon {
        margin-right: ${theme.spacing(4)};
      }
    `,
    flexRow: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    `,
    doublePadding: css``,
    actionButton: css`
      flex-shrink: 0;
      width: ${theme.spacing(10)};
      cursor: pointer;
      box-shadow: none;
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: center;
      padding: 0;
    `,
    mobileConnectButton: css`
      position: relative;
      margin-left: ${theme.spacing(4)};
    `,
  }
}
