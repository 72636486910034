/** @jsxImportSource @emotion/react */
import { Fade, Grid, Stack, Typography } from "@mui/material"
import { useRouter } from "next/router"
import React, { FC } from "react"
import { useTranslation } from "translation"

import { STABLECOINS } from "../../../../constants"

import { TokenIcon } from "../../../TokenIcon"
import { menuItems } from "../../constants"
import BackButton from "./BackButton"
import { useStyles } from "./styles"
import { unsafelyGetToken } from "utilities/misc"
import { formatPercentage } from "utilities/formatting"
import useGetMarkets from "queries/getMarkets/useGetMarkets"

const Title: FC = () => {
  const styles = useStyles()

  const { pathname, query } = useRouter()
  const { t } = useTranslation()

  const { data: { markets } = { markets: [] }, isLoading } = useGetMarkets()

  // Handle special case of Market Details page
  if (pathname.startsWith("/market/")) {
    const { fTokenId } = query
    const token = unsafelyGetToken(fTokenId as string)
    return (
      <div css={styles.marketDetailsLeftColumn}>
        <BackButton>
          <TokenIcon token={token} css={styles.backButtonTokenIcon} />
          <Typography variant="h4" fontWeight={700} css={styles.backButtonTokenSymbol}>
            {token.symbol}
          </Typography>
        </BackButton>
      </div>
    )
  }

  const currentItem = menuItems.find((item) => item.href === pathname)

  const stableYields = markets
    .filter((market: any) => STABLECOINS.includes(market.underlyingSymbol))
    .sort((a: any, b: any) => STABLECOINS.indexOf(a.underlyingSymbol) - STABLECOINS.indexOf(b.underlyingSymbol))
    .map((market: any) => ({
      token: unsafelyGetToken(market.underlyingSymbol.toLowerCase()),
      apy: formatPercentage(market.supplyApy).toFixed(1),
    }))
  if (pathname === "/") {
    return (
      <Fade in={markets.length > 0}>
        <Stack direction={{ xs: "column", sm: "row" }} sx={{ marginTop: { xs: "10em", lg: 1 } }}>
          <Typography whiteSpace="nowrap" variant="body1" mr={5} mb={{ xs: 3, sm: 0 }}>
            Supply APY:
          </Typography>
          <Grid container spacing={{ xs: 3, sm: 5 }} mb={{ xs: 3, sm: 0 }} alignItems="center">
            {stableYields.map((item: any) => (
              <Grid key={item.token.symbol} item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <TokenIcon token={item.token} />
                  <Typography variant="body1">{item.apy}%</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Fade>
    )
  }
  return currentItem ? <Typography variant="h4">{t(currentItem.i18nTitleKey)}</Typography> : null
}

export default Title
