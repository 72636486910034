import { TOKENS, FTOKENS } from "../constants"
import config from "config"
import mainContractAddresses from "contracts/addresses/main.json"
import BigNumber from "bignumber.js"
import { Token } from "types"
import { Address } from "viem"

export const unsafelyGetToken = (id: string) => TOKENS[id.toLowerCase() as keyof typeof TOKENS]
export const unsafelyGetFToken = (id: string) => FTOKENS[id as keyof typeof FTOKENS]

export const isRunningInOperaBrowser = () => typeof window !== "undefined" && !!(window as any)?.ethereum?.isOpera
export const isRunningInBinanceChainWallet = () => typeof window !== "undefined" && !!(window as any)?.BinanceChain

export const truncateAddress = (address: string) => `${address.slice(0, 4)}...${address.slice(-4)}`

export const getTokenByAddress = (address: string) => {
  let token: Token | undefined
  Object.keys(TOKENS)
    .filter((key) => Object.prototype.hasOwnProperty.call(TOKENS, key))
    .forEach((tokenId) => {
      const currentToken = TOKENS[tokenId as keyof typeof TOKENS]
      if (currentToken?.address === address) {
        token = currentToken
      }
    })
  return token
}

export const getFTokenByAddress = (address: string) => {
  let token: Token | undefined
  Object.keys(FTOKENS)
    .filter((key) => Object.prototype.hasOwnProperty.call(FTOKENS, key))
    .forEach((tokenId) => {
      const currentToken = FTOKENS[tokenId as keyof typeof FTOKENS]
      if (currentToken?.address.toLowerCase() === address.toLowerCase()) {
        token = currentToken
      }
    })
  return token
}

export const getContractAddress = (contractId: keyof typeof mainContractAddresses): Address =>
  mainContractAddresses[contractId][config.chainId] as Address

export type ExplorerUrlType = "address" | "token" | "tx"
export const explorerUrl = <T extends ExplorerUrlType = "address">(identifier: string, urlType?: T) => {
  const safeUrlType = urlType || "address"
  let suffix: string = identifier
  if (safeUrlType === "token") {
    suffix = unsafelyGetToken(identifier).address
  }
  return `${config.explorerUrl}/${safeUrlType}/${suffix}`
}

export const getBigNumber = (value?: BigNumber | string | number): BigNumber => {
  if (!value) {
    return new BigNumber(0)
  }
  if (BigNumber.isBigNumber(value)) {
    return value
  }
  return new BigNumber(value)
}

export const addRawTokenToWallet = async (token: Token) => {
  const isInBCW = isRunningInBinanceChainWallet()
  return (isInBCW ? ((window as any).BinanceChain as Record<string, string>) : (window as any).ethereum)?.request({
    method: "wallet_watchAsset",
    params: {
      type: "ERC20",
      options: {
        address: token.address,
        symbol: token.symbol,
        decimals: token.decimals,
        image: `${window.location.origin}${token.asset}`,
      },
    },
  })
}
