import { Global } from "@emotion/react"
import MuiTooltip, { TooltipProps as MUITooltipProps } from "@mui/material/Tooltip"
import React, { ReactElement } from "react"

import { useStyles } from "./styles"

export interface TooltipProps extends MUITooltipProps {
  title: string | ReactElement
}

export const Tooltip = ({ children, placement = "top", ...rest }: TooltipProps) => {
  const styles = useStyles()

  return (
    <>
      <Global styles={styles} />
      <MuiTooltip arrow placement={placement} {...rest}>
        <span>{children}</span>
      </MuiTooltip>
    </>
  )
}
