import { css } from "@emotion/react"
import { darken, lighten, useTheme } from "@mui/material"
import { borderRadius } from "muiTheme"

export const useStyles = () => {
  const theme = useTheme()
  return {
    noResults: css`
      padding: ${theme.spacing(2)};
      text-align: center;
      color: ${theme.palette.action.active};
      width: 100%;
    `,
    root: css`
      overflow: hidden;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: ${theme.spacing(2)};
      min-height: 12rem;

      ${theme.breakpoints.down("sm")} {
        padding-bottom: ${theme.spacing(0)};
      }
    `,
    titleContainer: css`
      ${theme.breakpoints.down("lg")} {
        margin-bottom: ${theme.spacing(4)};
      }
    `,
    title: css`
      padding: ${theme.spacing(3, 0, 3, 7)};
      ${theme.breakpoints.down("lg")} {
        min-width: 9em;
      }
    `,
    tableWrapperMobileWrap: css`
      overflow: hidden;
    `,
    tableWrapperMobile: ({ clickable, loading }: { clickable: boolean; loading?: boolean }) => css`
      padding: ${theme.spacing(4, 0)};
      margin: ${theme.spacing(2, 0)};
      // ${!loading && `border: 1px solid ${theme.palette.divider};`}
      ${!!loading && `background-color: ${theme.palette.action.hover};`}

      transition: background-color 0.3s ease-in-out;
      border-radius: ${borderRadius.large}px;
      :first-of-type {
        margin-top: 0;
      }

      ${clickable &&
      !loading &&
      css`
        cursor: pointer;
        :hover {
          background-color: ${theme.palette.action.hover};
        }
      `};
    `,
    rowTitleMobile: css`
      padding-left: ${theme.spacing(4)};
      padding-right: ${theme.spacing(4)};
      margin-bottom: ${theme.spacing(4)};
    `,
    delimiterMobile: css`
      margin: ${theme.spacing(4)};
    `,
    getTableRow: ({ clickable }: { clickable: boolean }) => css`
      ${clickable &&
      css`
        cursor: pointer;
      `}
    `,
    rowWrapperMobile: css`
      display: grid;
    `,
    cellMobile: css`
      display: flex;
      flex-direction: column;
      padding-left: ${theme.spacing(4)};
      padding-right: ${theme.spacing(4)};
    `,
    columnLabelMobile: css`
      font-size: ${theme.typography.small2.fontSize};
      font-weight: ${theme.typography.small2.fontWeight};
      opacity: ${theme.typography.small2.opacity};
    `,
    cellValueMobile: css`
      padding-top: ${theme.spacing(2)};
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
    `,
    loader: css`
      margin-bottom: ${theme.spacing(4)};
    `,
    table: ({ minWidth }: { minWidth: string }) => css`
      min-width: ${minWidth};
      table-layout: fixed;

      .MuiTableCell-root {
        border-width: 0;
        font-weight: ${theme.typography.body1.fontWeight};
        flex-direction: row;
        font-size: ${theme.typography.body1.fontSize};
        text-transform: none;
      }

      .MuiTableCell-root:first-of-type {
        padding-left: ${theme.spacing(7)};
      }

      .MuiTableCell-root:last-child {
        padding-right: ${theme.spacing(7)};
      }
    `,
    tableSortLabel: ({ orderable }: { orderable: boolean }) => css`
      cursor: ${orderable ? "pointer" : "auto"};
      &.MuiTableSortLabel-root {
        span {
          font-size: ${theme.typography.small1.fontSize};
          font-weight: ${theme.typography.small1.fontWeight};
          opacity: ${theme.typography.small1.opacity};
        }
      }

      span.MuiTableSortLabel-icon {
        display: none;
      }

      .MuiSvgIcon-root {
        display: block;
        margin-left: ${theme.spacing(2)};
        transform: rotate(0deg);
      }
      .MuiTableSortLabel-iconDirectionDesc {
        transform: rotate(180deg);
      }

      &.MuiTableSortLabel-root.Mui-active:hover {
        color: ${theme.palette.text.secondary};
        .MuiTableSortLabel-iconDirectionDesc {
          font-size: ${theme.typography.small2.fontSize};
          font-weight: ${theme.typography.small2.fontWeight};
          opacity: ${theme.typography.small2.opacity};
        }
        .MuiTableSortLabel-iconDirectionAsc {
          font-size: ${theme.typography.small2.fontSize};
          font-weight: ${theme.typography.small2.fontWeight};
          opacity: ${theme.typography.small2.opacity};
        }
      }
    `,
    tableSortLabelIconsContainer: css`
      margin-top: -2px;
    `,
    tableSortLabelIcon: ({ active }: { active: boolean }) => css`
      &.MuiTableSortLabel-icon {
        fill: ${theme.palette.text.primary};
      }
      .Mui-active &.MuiTableSortLabel-icon {
        fill: ${active ? theme.palette.interactive.success : theme.palette.text.primary};
        color: ${active ? theme.palette.interactive.success : theme.palette.text.primary};
      }
    `,
    cellInner: css`
      text-overflow: ellipsis;
    `,
  }
}
