import { IPCOMPLY_ENDPOINT, WALLETSCREEN_ENDPOINT } from "config"

export interface IPComplianceStatus {
  ip: string
  isRestricted: boolean
  isGeoRestricted?: boolean
  isHighRiskRestricted?: boolean
  reason: string
  country_name?: string
  country_code?: string
  region_name?: string
  region_code?: string
  city?: string
}

//Use to log that this ip check happened from flux
const FLUX_IP_RESTRICTION_TYPE = "FLUX"

// fetching the IPComplianceStatus also logs all requests
// to the ipdata api. We pass in an optional acceptedTerms param
// to denote if a user had agreed to the terms. If so, we also log
// if their IP address was valid or not
export const fetchIpComplianceStatus = async ({
  acceptedTerms = undefined,
}: {
  acceptedTerms?: boolean | undefined
} = {}): Promise<IPComplianceStatus> => {
  try {
    let body = {}
    if (acceptedTerms !== undefined) {
      body = { acceptedTerms }
    }

    const ipComplianceStatusResponse = await fetch(
      `${IPCOMPLY_ENDPOINT}?restriction_type=${FLUX_IP_RESTRICTION_TYPE}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
    )
    if (ipComplianceStatusResponse.status !== 200) {
      throw new Error(ipComplianceStatusResponse.statusText)
    }

    return await ipComplianceStatusResponse.json()
  } catch (error) {
    // logger.debug("Exception fetching IP compliance status")
    throw error
  }
}

// Represents the API response
export interface WalletComplianceStatus {
  address: string
  isRestricted: boolean
  reason: string
}

export const fetchWalletComplianceStatus = async (walletAddress: string): Promise<WalletComplianceStatus> => {
  try {
    const walletComplianceStatus = await fetch(`${WALLETSCREEN_ENDPOINT}?address=${walletAddress}`)
    if (walletComplianceStatus.status !== 200) {
      // logger.error("An error occured while screening the user's wallet")
      throw new Error(walletComplianceStatus.statusText)
    }
    const res = await walletComplianceStatus.json()
    return res
  } catch (error) {
    return { address: walletAddress, isRestricted: true, reason: "" }
  }
}
