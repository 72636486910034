import BigNumber from "bignumber.js"
import { MAINNET_FTOKENS } from "fTokens"
import { MAINNET_TOKENS } from "tokens"

export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000"

export const DAYS_PER_YEAR = 365

import { ChainIds } from "types"

export const BLOCK_TIME_MS = 3000

export const BLOCKS_PER_DAY = 2628000 / 365

export const EXPLORER_URLS = {
  [ChainIds.MAINNET]: "https://etherscan.io",
  [ChainIds.BSC]: "https://bscscan.com",
}

// Specific to the Compound protocol (see https://compound.finance/docs)
export const COMPOUND_MANTISSA = 1e18

// Default allowance set when approving a token to be used from an account
export const ALLOWANCE_AMOUNT_WEI = new BigNumber(2).pow(256).minus(1).toFixed(0)

export const DEFAULT_REFETCH_INTERVAL_MS = BLOCK_TIME_MS * 3 // every 3 blocks (9 seconds)

export const MAX_UINT256 = new BigNumber(2).pow(256).minus(1)

export const PLACEHOLDER_KEY = "-"

// Local storage keys
export const LS_KEY_ACCEPTED_TERMS = "accepted-terms"

// URLs
export const FLUX_MEDIUM_URL = "https://blog.fluxfinance.com"
export const FLUX_DISCORD_URL = "https://discord.fluxfinance.com/"
export const FLUX_TWITTER_URL = "https://twitter.com/FluxDeFi"
export const FLUX_DOCS_URL = "https://docs.fluxfinance.com"
export const FLUX_DAO_URL = "https://www.tally.xyz/gov/ondo-dao"
export const FLUX_FORUM_URL = "https://forum.fluxfinance.com"
export const FLUX_PRIVACY_URL = "https://docs.fluxfinance.com/legal/privacy-policy"
export const FLUX_TERMS_URL = "https://docs.fluxfinance.com/legal/terms-of-service"
export const FLUX_COOKIES_URL = "https://docs.fluxfinance.com/legal/cookies-policy"
export const FLUX_BOUNTIES_URL = "https://immunefi.com/bounty/fluxfinance/"
export const FLUX_GITHUB_URL = "https://github.com/flux-finance"
export const FLUX_TELEGRAM_URL = "https://t.me/ondocommunity"

// Query and mutation keys
export enum FunctionKey {
  // Queries
  GET_KYC_STATUS = "GET_KYC_STATUS",
  GET_BLOCK_NUMBER = "GET_BLOCK_NUMBER",
  GET_MARKETS = "GET_MARKETS",
  GET_MARKET_HISTORY = "GET_MARKET_HISTORY",
  GET_ASSETS_IN_ACCOUNT = "GET_ASSETS_IN_ACCOUNT",
  GET_TOKEN_ALLOWANCE = "GET_TOKEN_ALLOWANCE",
  GET_BALANCE_OF = "GET_BALANCE_OF",
  GET_FTOKEN_CASH = "GET_FTOKEN_CASH",
  GET_FTOKEN_BORROW_BALANCE = "GET_FTOKEN_BORROW_BALANCE",
  GET_FTOKEN_BALANCE = "GET_FTOKEN_BALANCE",
  GET_FTOKEN_BALANCES_ALL = "GET_FTOKEN_BALANCES_ALL",
  GET_FTOKEN_INTEREST_RATE_MODEL = "GET_FTOKEN_INTEREST_RATE_MODEL",
  GET_FTOKEN_APY_SIMULATIONS = "GET_FTOKEN_APY_SIMULATIONS",
  GET_TRANSACTIONS = "GET_TRANSACTIONS",

  // Mutations
  ENTER_MARKETS = "ENTER_MARKETS",
  EXIT_MARKET = "EXIT_MARKET",
  APPROVE_TOKEN = "APPROVE_TOKEN",
  SUPPLY = "SUPPLY",
  REDEEM = "REDEEM",
  REDEEM_UNDERLYING = "REDEEM_UNDERLYING",
  REPAY_FTOKEN = "REPAY_FTOKEN",
  BORROW_FTOKEN = "BORROW_FTOKEN",
}

// The order here is the order that will be shown in the landing page header
export const STABLECOINS = ["USDC", "USDT", "DAI", "FRAX"]

export const TOKENS = MAINNET_TOKENS
export const FTOKENS = MAINNET_FTOKENS

// The safe collateral factor.
// We use this to determine whether an operation is "safe" or not.
// This is applied on top of the asset collateral factor, which for OUSG is 92%.
// You can find the OUSG collateral factor by going to the Comptroller contract (https://etherscan.io/address/0x95Af143a021DF745bc78e845b54591C53a8B3A51#readProxyContract)
// and reading `markets` passing in the fOUSG token address (0x1dD7950c266fB1be96180a8FDb0591F70200E018)
export const COLLATERAL_FACTOR = 95

export const EXPECTED_BLOCKS_PER_YEAR = 2628000
