import { css } from "@emotion/react"
import { darken, useTheme } from "@mui/material"

export const useStyles = () => {
  const theme = useTheme()
  return {
    padding: css`
      flex: 1;
      display: flex;
      flex-direction: column;
    `,
    layout: css`
      display: flex;
      flex: 1;
      flex-direction: row;
      padding: 0 !important;
      min-height: 100vh;

      overflow: hidden;
      ${theme.breakpoints.down("lg")} {
        flex-direction: column;
      }
    `,
    banner: css`
      margin: ${theme.spacing(4, 10, 0)};
      ${theme.breakpoints.down("xxl")} {
        margin: ${theme.spacing(6, 6, 0)};
      }
      ${theme.breakpoints.down("lg")} {
        margin: ${theme.spacing(4, 4, 0)};
      }
    `,
    bannerLink: css`
      color: ${theme.palette.text.primary};
      text-decoration: underline;
    `,
    main: css`
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(4)};
      ${theme.breakpoints.down("sm")} {
        gap: ${theme.spacing(2)};
      }
      height: 100%;
    `,
  }
}
