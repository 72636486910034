/** @jsxImportSource @emotion/react */
import { GlobalStyles } from "@mui/material"
import React, { FC } from "react"
import { ToastOptions, toast as toastify } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

import { Button } from "../Button"
import { Icon } from "../Icon"
import { Notice } from "../Notice"
import { NoticeVariant } from "../Notice/types"
import { customToastGlobalStyles, useStyles } from "./styles"

interface ToastArgs {
  message: string
}
interface ToastProps extends ToastArgs {
  type: NoticeVariant
}
interface CloseButtonProps {
  closeToast: () => void
}

const CloseButton: FC<CloseButtonProps> = ({ closeToast }) => {
  const classes = useStyles()
  return (
    <Button css={classes.btnClose} onClick={closeToast} variant="text">
      <Icon name="close" />
    </Button>
  )
}

const ToastComponent: FC<ToastProps> = ({ message, type = "info" }) => {
  const classes = useStyles()
  return (
    <>
      <GlobalStyles styles={customToastGlobalStyles} />
      <Notice css={classes.noticeContainer} description={message} variant={type} />
    </>
  )
}

const defaultOptions: ToastOptions = {
  theme: "light",
  position: "top-right",
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  closeButton: CloseButton as ToastOptions["closeButton"],
}

export const toast = ({ message, type = "info" }: ToastProps, options?: ToastOptions) => {
  const toastId = toastify(<ToastComponent message={message} type={type} />, {
    ...defaultOptions,
    ...options,
    className: "toast-container",
  })
  const dismissToast = () => toastify.dismiss(toastId)
  return dismissToast
}

toast.info = (content: ToastArgs, options?: ToastOptions) => toast({ ...content, type: "info" }, options)
toast.error = (content: ToastArgs, options?: ToastOptions) => toast({ ...content, type: "error" }, options)
toast.success = (content: ToastArgs, options?: ToastOptions) => toast({ ...content, type: "success" }, options)
toast.warning = (content: ToastArgs, options?: ToastOptions) => toast({ ...content, type: "warning" }, options)
toast.update = toastify.update

// Transaction Toasts

toast.txWallet = () => toast.info({ message: "Please confirm the transaction from your wallet" })

toast.txSubmitted = (txHash: string) =>
  toast.info({ message: "Your transaction has been submitted." }, { closeButton: <ViewTxButton txHash={txHash} /> })

toast.txConfirmed = (txHash: string) =>
  toast.success({ message: "Your transaction was confirmed." }, { closeButton: <ViewTxButton txHash={txHash} /> })

const ViewTxButton = ({ txHash }: { txHash: string }) => {
  return (
    <div style={{ position: "absolute", bottom: "12px", right: "12px" }}>
      <Button
        variant="primary"
        small
        style={{ height: "20px" }}
        onClick={() => window.open(`https://etherscan.io/tx/${txHash}`)}
      >
        View
      </Button>
    </div>
  )
}
