/** @jsxImportSource @emotion/react */
import Typography from "@mui/material/Typography"
import BigNumber from "bignumber.js"
import { FC } from "react"
import { Token } from "types"
import { convertWeiToTokens } from "utilities/calc"
import AddTokenToWalletButton from "components/AddTokenToWallet"

import { BscLink } from "../BscLink"
import { Icon } from "../Icon"
import { Modal, ModalProps } from "../Modal"
import { TokenIcon } from "../TokenIcon"
import { useStyles } from "./styles"

export interface SuccessfulTransactionModalProps extends Omit<ModalProps, "children"> {
  title: string
  content?: any
  transactionHash: string
  amount?: {
    token: Token
    valueWei: BigNumber
  }
  className?: string
  addToMetamaskToken?: Token
}

export const SuccessfulTransactionModal: FC<SuccessfulTransactionModalProps> = ({
  className,
  title,
  content,
  amount,
  transactionHash,
  isOpen,
  handleClose,
  addToMetamaskToken,
}) => {
  const styles = useStyles()

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <div className={className} css={styles.container}>
        <Icon name="check" css={styles.headerIcon} />

        <h3 css={styles.title}>{title}</h3>

        <div css={styles.messageContainer}>
          {!!content && (
            <Typography variant="small1" component="p">
              {content}
            </Typography>
          )}
          {amount && (
            <div css={styles.amountContainer}>
              <TokenIcon token={amount.token} css={styles.amountTokenIcon} />

              <Typography variant="small1" component="span">
                {convertWeiToTokens({
                  valueWei: amount.valueWei,
                  token: amount.token,
                  returnInReadableFormat: true,
                })}
              </Typography>
            </div>
          )}
        </div>

        {addToMetamaskToken && <AddTokenToWalletButton token={addToMetamaskToken} />}
        <div style={{ marginTop: ".5em" }}>
          <BscLink hash={transactionHash} urlType="tx" />
        </div>
      </div>
    </Modal>
  )
}
