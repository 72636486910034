/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react"
import { Chip, Divider, Skeleton, Stack, Typography } from "@mui/material"
import Paper from "@mui/material/Paper"
import TableMUI from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import Box from "@mui/material/Box"
import { MouseEvent, ReactNode, useMemo, useState } from "react"
import Link from "next/link"
import Head from "./Head"
import TableCards from "./TableCards"
import { useStyles } from "./styles"

export interface TableRowProps {
  key: string | number
  render?: () => ReactNode | string
  value: string | number | boolean
  align?: "left" | "center" | "right"
  colSpan?: number
}

export interface TableColumn {
  key: string
  label: string
  orderable: boolean
  align?: "left" | "center" | "right"
}

export interface TableBaseProps {
  title?: string
  titleChipLabel?: string
  data: TableRowProps[][]
  columns: TableColumn[]
  cardColumns?: { key: string; label: string; orderable: boolean }[]
  rowKeyIndex: number
  minWidth?: string
  initialOrder?: {
    orderBy: string
    orderDirection: "asc" | "desc"
  }
  rowOnClick?: (e: MouseEvent<HTMLDivElement>, row: TableRowProps[]) => void
  className?: string
  tableCss?: SerializedStyles
  cardsCss?: SerializedStyles
  gridTemplateColumnsCards?: string
  gridTemplateRowsMobile?: string /* used for mobile view if table has to display more than 1 row */
  isFetching?: boolean
  emptyTableMessage?: string
}

interface TableCardRowOnClickProps extends TableBaseProps {
  rowOnClick?: (e: MouseEvent<HTMLDivElement>, row: TableRowProps[]) => void
  getRowHref?: undefined
}

interface TableCardHrefProps extends TableBaseProps {
  rowOnClick?: undefined
  getRowHref?: (row: TableRowProps[]) => string
}

export type TableProps = TableCardRowOnClickProps | TableCardHrefProps

export const Table = ({
  columns,
  cardColumns,
  data,
  title,
  titleChipLabel,
  minWidth,
  initialOrder,
  rowOnClick,
  getRowHref,
  rowKeyIndex,
  className,
  tableCss,
  cardsCss,
  isFetching,
  emptyTableMessage,
}: TableProps) => {
  const styles = useStyles()

  const [orderBy, setOrderBy] = useState<typeof columns[number]["key"] | undefined>(initialOrder?.orderBy)

  const [orderDirection, setOrderDirection] = useState<"asc" | "desc" | undefined>(initialOrder?.orderDirection)

  const onRequestOrder = (property: typeof columns[number]["key"]) => {
    let newOrder: "asc" | "desc" = "asc"
    if (property === orderBy) {
      newOrder = orderDirection === "asc" ? "desc" : "asc"
    }
    setOrderBy(property)
    setOrderDirection(newOrder)
  }

  const rows = useMemo(() => {
    // order in place
    if (!orderBy) {
      return data
    }
    const rowIndex = columns.findIndex((column) => column.key === orderBy)
    const newRows = [...data]
    newRows.sort((a, b) => {
      const formattedValueA = Number.isNaN(+a[rowIndex]?.value) ? a[rowIndex]?.value : +a[rowIndex]?.value
      const formattedValueB = Number.isNaN(+b[rowIndex]?.value) ? b[rowIndex]?.value : +b[rowIndex]?.value

      if (formattedValueA < formattedValueB) {
        return orderDirection === "asc" ? -1 : 1
      }
      if (formattedValueA > formattedValueB) {
        return orderDirection === "asc" ? 1 : -1
      }
      return 0
    })
    return newRows
  }, [data, orderBy, orderDirection])

  return (
    <Box css={styles.root} className={className}>
      {title && (
        <Stack spacing={3} direction="row" alignItems="center" css={styles.titleContainer}>
          <Typography css={styles.title} variant="body1">
            {title}
          </Typography>
          {titleChipLabel && <Chip variant="outlined" label={titleChipLabel} />}
        </Stack>
      )}
      <Paper sx={{ p: 0, overflow: "hidden" }} css={tableCss}>
        <TableContainer>
          <TableMUI css={styles.table({ minWidth: minWidth ?? "0" })} aria-label={title}>
            <Head columns={columns} orderBy={orderBy} orderDirection={orderDirection} onRequestOrder={onRequestOrder} />

            <TableBody>
              {!isFetching && !rows.length && (
                <TableRow
                  hover
                  key={"no-records"}
                  css={styles.getTableRow({ clickable: false })}
                  style={{ pointerEvents: "none" }}
                >
                  <TableCell key="empty-table" align="center" colSpan={columns.length}>
                    <Stack alignItems="center" my={10}>
                      <Typography variant="body1" color="textSecondary">
                        {emptyTableMessage || "No records available"}
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              )}
              {isFetching &&
                [1, 2, 3, 4, 5].map((i) => (
                  <TableRow key={`loading-${i}`} css={styles.getTableRow({ clickable: false })}>
                    {columns.map(({ key, label, orderable, align }) => (
                      <TableCell key={`${key}-table`} align={align}>
                        <Stack alignItems={align === "right" ? "flex-end" : "flex-start"} sx={{ py: 0.8 }}>
                          <Skeleton
                            animation={false}
                            variant="rectangular"
                            style={{ borderRadius: "1em", height: "1em", width: "100%" }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation={false}
                            style={{
                              borderRadius: "8px",
                              marginTop: ".4rem",
                              height: ".8em",
                              width: "75%",
                            }}
                          />
                        </Stack>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              {!isFetching &&
                rows.map((row, idx) => {
                  const rowKey = `${row[rowKeyIndex].value.toString()}-${idx}-table`
                  return (
                    <TableRow
                      component={getRowHref ? (Link as any) : undefined}
                      href={getRowHref && getRowHref(row)}
                      hover={!!rowOnClick || !!getRowHref}
                      key={rowKey}
                      css={styles.getTableRow({ clickable: !!rowOnClick })}
                      onClick={rowOnClick && ((e: MouseEvent<HTMLDivElement>) => rowOnClick(e, row))}
                    >
                      {row.map(({ key, render, align, colSpan }: TableRowProps) => {
                        const cellContent = render?.()
                        const cellTitle = typeof cellContent === "string" ? cellContent : undefined
                        return (
                          cellContent && (
                            <TableCell key={`${rowKey}-${key}-table`} align={align} colSpan={colSpan || 1}>
                              {cellContent}
                            </TableCell>
                          )
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </TableMUI>
        </TableContainer>
      </Paper>
      <TableCards
        isFetching={isFetching}
        rows={rows}
        rowKeyIndex={rowKeyIndex}
        rowOnClick={rowOnClick}
        getRowHref={getRowHref}
        columns={cardColumns || columns}
        css={cardsCss}
        emptyTableMessage={emptyTableMessage}
      />
    </Box>
  )
}
