/** @jsxImportSource @emotion/react */
import { Tooltip } from "@mui/material"
import Typography from "@mui/material/Typography"
import React, { FC, ReactElement } from "react"

import { ProgressBar, ProgressBarProps } from ".."
import { useStyles } from "./styles"

interface LabeledProgressBarProps extends ProgressBarProps {
  greyLeftText?: string
  whiteLeftText?: string | ReactElement
  greyRightText?: string | ReactElement
  whiteRightText?: string
  className?: string
}

export const LabeledProgressBar: FC<LabeledProgressBarProps> = ({
  greyRightText,
  whiteRightText,
  greyLeftText,
  whiteLeftText,
  className,
  ...progressBarProps
}) => {
  const styles = useStyles()
  return (
    <>
      <div className={className} css={styles.topProgressBarLegend}>
        <div css={styles.inlineContainer}>
          {greyLeftText && (
            <Typography component="span" variant="small2" css={styles.inlineLabel}>
              {greyLeftText}
            </Typography>
          )}
          {whiteLeftText && (
            <Typography component="span" variant="small1" css={styles.inlineValue}>
              {whiteLeftText}
            </Typography>
          )}
        </div>

        <div css={styles.inlineContainer}>
          {greyRightText && (
            <Typography component="span" variant="small2" css={styles.inlineLabel}>
              {greyRightText}
            </Typography>
          )}

          {whiteRightText && (
            <Tooltip title="The maximum borrowable amount is determined by the value of your supplied assets and the borrowable asset's collateral factor.">
              <Typography component="span" variant="small1" css={styles.inlineValue}>
                {whiteRightText}
              </Typography>
            </Tooltip>
          )}
        </div>
      </div>

      <ProgressBar {...progressBarProps} />
    </>
  )
}
