/* https://mui.com/customization/theming/ */
import { ThemeOptions, createTheme, alpha } from "@mui/material"

const colors = {
  purple: {
    main: "#6100FF",
    light: "#6A36FF",
  },
  yellow: {
    main: "#F0AA55",
    light: "#F5DDAC",
  },
  red: {
    main: "#FF7663",
    light: "#FFA498",
  },
  blue: {
    main: "#55D7FF",
    light: "#93E5FF",
  },
  green: {
    main: "#3FB950",
    light: "#7EE787",
  },
  base: {
    white: "#FFFFFF",
    offWhite: "#F2F3F6",
    greyLight: "#DCDDE0",
    greyMid: "#96979A",
    greyDark: "#424346",
    greyDark2: "#222326",
    black: "#000000",
  },
  transparency: {
    white80: 0.8,
    white48: 0.48,
    white16: 0.16,
    black80: 0.8,
    black40: 0.4,
    black16: 0.16,
  },
}

const fontFamily = ["Alliance", "Arial", "sans-serif"].join(",")

export const FONTS = {
  primary: fontFamily,
  secondary: fontFamily,
}

export const PALETTE = {
  mode: "light",
  background: {
    default: colors.base.offWhite,
    paper: colors.base.white,
    black: colors.base.black,
  },
  primary: {
    light: colors.base.black,
    main: colors.base.black,
    dark: colors.base.black,
  },
  secondary: {
    light: colors.purple.main,
    main: colors.purple.main,
    dark: colors.purple.main,
  },
  text: {
    primary: colors.base.black,
    secondary: colors.base.black,
    disabled: colors.base.black,
  },
  button: {
    main: colors.base.greyLight,
    medium: colors.base.greyMid,
    dark: colors.base.black,
  },
  interactive: {
    primary: colors.purple.main,
    success: colors.green.main,
    success10: colors.green.light,
    success50: colors.green.main,
    error: colors.red.main,
    error50: colors.red.light,
    tan: colors.base.greyDark,
    delimiter: colors.base.greyLight,
    warning: colors.yellow.main,
    hover: colors.base.greyLight,
  },
}

export const BREAKPOINTS = {
  values: {
    xs: 0,
    sm: 620,
    md: 920,
    lg: 1180,
    xl: 1780,
    xxl: 1820,
  },
}

export const SPACING = 5

export type TypographyVariant =
  | "button"
  | "caption"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "inherit"
  | "overline"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "small1"
  | "small2"
  | undefined

export const borderRadius = {
  verySmall: 3,
  small: 6,
  medium: 16,
  large: 24,
}

export const SHAPE = {
  borderRadius: borderRadius.medium,
  iconSize: {
    small: SPACING * 2,
    medium: SPACING * 3,
    large: SPACING * 4,
    xLarge: SPACING * 5,
    xxLarge: SPACING * 10,
  },
  footerHeight: "56px",
  bannerHeight: "56px",
  drawerWidthDesktop: "170px",
  drawerWidthTablet: "80px",
}

const { breakpoints } = createTheme({ breakpoints: BREAKPOINTS })

export default createTheme({
  spacing: SPACING,
  palette: PALETTE,
  breakpoints: BREAKPOINTS,
  shape: SHAPE,
  typography: {
    fontWeightRegular: 400,
    fontSize: 16,
    htmlFontSize: 16,
    fontFamily: "Alliance, Helvetica Neue, Helvetica, Sans-serif",

    h1: {
      fontSize: 64,
      lineHeight: "72px",
      fontFamily: "Alliance",
      fontWeight: "normal",
      [breakpoints.down("xs")]: {
        fontSize: 40,
        lineHeight: "46px",
      },
      [breakpoints.down("sm")]: {
        fontSize: 48,
        lineHeight: "56px",
      },
    },
    h2: {
      fontSize: 48,
      lineHeight: "56px",
      fontWeight: "normal",
      fontFamily: "Alliance",

      // [breakpoints.down('sm')]: {
      //   fontSize: 40,
      //   lineHeight: '46px',
      // },
    },
    h3: {
      fontSize: 40,
      lineHeight: "46px",
      fontWeight: "normal",
      fontFamily: "Alliance",

      // [breakpoints.down('sm')]: {
      //   fontSize: 32,
      //   lineHeight: '36px',
      // },
    },
    h4: {
      fontSize: 32,
      lineHeight: "36px",
      fontWeight: "normal",
      fontFamily: "Alliance",
      // [breakpoints.down('sm')]: {
      //   fontSize: 24,
      //   lineHeight: '32px',
      // },
    },
    h5: {
      fontSize: 24,
      lineHeight: "28px",
      fontWeight: "normal",
      fontFamily: "Alliance",
      // [breakpoints.down('sm')]: {
      //   fontSize: 18,
      //   lineHeight: '28px',
      // },
    },
    h6: { fontSize: 18, lineHeight: "24px", fontFamily: "Alliance", fontWeight: "normal" },

    body1: { fontSize: 16, lineHeight: "24px" },
    body2: {
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: "normal",
      color: alpha(PALETTE.text.primary, 0.6),
    },

    small1: { fontSize: 12, lineHeight: "16px", fontWeight: 400, opacity: 0.6 },
    small2: { fontSize: 12, lineHeight: "16px", fontWeight: 400, opacity: 0.6 },
    tiny: { fontSize: 12, lineHeight: "16px", fontWeight: 400 },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
          backgroundColor: PALETTE.background.paper,
          borderRadius: borderRadius.large,
          padding: `${SPACING * 6}px ${SPACING * 7}px`,
          boxShadow: "none",
          [breakpoints.down("sm")]: {
            borderRadius: borderRadius.medium,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        paper: {
          padding: 0,
          borderRadius: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          top: 0,
          border: "none",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "auto",
        },
        label: {
          paddingRight: ".5rem",
          paddingLeft: ".5rem",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          verticalAlign: "top",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 40,
          height: 24,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(16px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                backgroundColor: colors.base.black,
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: "#33cf4d",
              border: `6px solid ${colors.base.white}`,
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color: colors.base.greyMid,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.7,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 20,
            height: 20,
          },
          "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: colors.base.greyLight,
            opacity: 1,
          },
        },
      },
    },
  },
} as ThemeOptions)
