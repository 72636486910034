import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"
import React from "react"

import SvgArrowRight from "components/Icon/icons/arrowRight"
import SvgArrowShaft from "components/Icon/icons/arrowShaft"

export const CookiePopover = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 100,
  [theme.breakpoints.down("sm")]: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  [theme.breakpoints.up("sm")]: {
    bottom: theme.spacing(3),
    right: "unset",
    left: theme.spacing(3),
    maxWidth: "27rem",
    width: "100%",
  },
}))

export const CookieAccordion = ({
  name,
  details,
  enabled,
  expanded,
  onExpand,
  onChange,
  alwaysOn,
  key,
}: {
  name: string
  details: string
  enabled: boolean
  expanded: boolean
  onExpand: () => void
  onChange: (checked: boolean) => void
  alwaysOn?: boolean
  key: string
}) => (
  <>
    <Accordion key={name} {...{ expanded, onChange: onExpand }} sx={{ py: 2 }}>
      <AccordionSummary style={{ padding: 0, margin: 0 }}>
        <Stack
          spacing={0}
          sx={{
            flexGrow: 1,
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          direction="row"
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              marginBottom: "-5px",
              transition: "transform 0.3s ease-in-out",
              mr: 1,
              transform: expanded ? "rotate(90deg)" : "rotate(0deg)",
            }}
          >
            <SvgArrowRight />
          </Box>
          <Typography variant="body1">{name}</Typography>
          <div style={{ flexGrow: 1 }} />
          {alwaysOn ? (
            <Chip sx={{ mt: { xs: 2, sm: 0 } }} variant="outlined" label="Always Enabled" color="primary" />
          ) : (
            <Switch
              checked={enabled}
              size="medium"
              color="primary"
              onClick={(e) => e.stopPropagation()}
              onChange={(e, c) => onChange(c)}
            />
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1" color="GrayText">
          {details}
        </Typography>
      </AccordionDetails>
    </Accordion>
  </>
)
