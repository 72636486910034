import * as React from "react"
import { SVGProps } from "react"

const SvgSort = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.727 4.286 4.364 0 0 4.286h8.727Z" />
  </svg>
)

export default SvgSort
