/** @jsxImportSource @emotion/react */
import { FC, useContext } from "react"
import ConnectButton from "components/Layout/ConnectButton"
import { NoticeInfo } from "../Notice"
import { useStyles } from "./styles"
import { AuthContext } from "context/AuthContext"
export interface PromptProps {
  message: string
  className?: string
  children: React.ReactNode
}

export const ConnectWallet: FC<PromptProps> = ({ message, className, children }) => {
  const { address } = useContext(AuthContext)
  const styles = useStyles()

  // Render prompt if user aren't connected with any wallet
  if (!!address) {
    return <div>{children}</div>
  }
  return (
    <div css={styles.container} className={className}>
      <NoticeInfo css={styles.notice} description={message} />
      <ConnectButton css={styles.buttonContainer} />
    </div>
  )
}
