import noop from "noop-ts"
import React, { FC, createContext, useState } from "react"

import { SuccessfulTransactionModal, SuccessfulTransactionModalProps } from "components/SuccessfulTransactionModal"

export type OpenSuccessfulTransactionModalInput = Pick<
  SuccessfulTransactionModalProps,
  "title" | "content" | "transactionHash" | "amount" | "addToMetamaskToken"
>

export interface SuccessfulTransactionModalContextValue {
  openSuccessfulTransactionModal: (params: OpenSuccessfulTransactionModalInput) => void
  closeSuccessfulTransactionModal: () => void
}

export const SuccessfulTransactionModalContext = createContext<SuccessfulTransactionModalContextValue>({
  openSuccessfulTransactionModal: noop,
  closeSuccessfulTransactionModal: noop,
})

export const SuccessfulTransactionModalProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpened] = useState(false)
  const [modalProps, setModalProps] = useState<OpenSuccessfulTransactionModalInput | undefined>()

  const openSuccessfulTransactionModal = (params: OpenSuccessfulTransactionModalInput) => {
    setModalProps(params)
    setIsOpened(true)
  }

  const closeSuccessfulTransactionModal = () => {
    setIsOpened(false)
    setModalProps(undefined)
  }

  return (
    <SuccessfulTransactionModalContext.Provider
      value={{
        openSuccessfulTransactionModal,
        closeSuccessfulTransactionModal,
      }}
    >
      {modalProps && (
        <SuccessfulTransactionModal isOpen={isOpen} handleClose={closeSuccessfulTransactionModal} {...modalProps} />
      )}

      {children}
    </SuccessfulTransactionModalContext.Provider>
  )
}
