import { css } from "@emotion/react"
import { useTheme } from "@mui/material"
import { borderRadius } from "muiTheme"

export const useModalStyles = ({
  hasTitleComponent,
  noHorizontalPadding,
}: {
  hasTitleComponent: boolean
  noHorizontalPadding?: boolean
}) => {
  const theme = useTheme()

  return {
    box: css`
      position: relative;
      margin: 20vh auto 0 auto;
      // left: calc(50% + ${theme.shape.drawerWidthDesktop});
      // transform: translate(calc(-50% - (${theme.shape.drawerWidthDesktop}) / 2), -50%);
      margin-top: ${theme.spacing(4)};

      width: calc(100% - ${theme.spacing(4)});
      max-width: ${theme.spacing(100)};
      border-radius: ${borderRadius.large}px;
      background-color: ${theme.palette.background.paper};
      overflow: hidden;
      overflow-y: scroll;
      max-height: calc(100% - ${theme.spacing(4)});
      &::-webkit-scrollbar {
        display: none;
      }
    `,
    scrollWrapper: css`
      border-radius: ${borderRadius.large}px;
    `,
    titleWrapper: css`
      padding-left: ${theme.spacing(4)};
      padding-right: ${theme.spacing(4)};
      padding-top: ${theme.spacing(4)};
      padding-bottom: ${hasTitleComponent ? theme.spacing(4) : 0};
      border-bottom: ${hasTitleComponent ? `1px solid ${theme.palette.divider}` : 0};
      position: sticky;
      top: 0;
      z-index: 10;
      border-radius: ${borderRadius.large}px ${borderRadius.large}px 0 0;

      background-color: ${theme.palette.background.paper};
      margin-bottom: ${hasTitleComponent ? theme.spacing(4) : 0};
      display: flex;
      align-items: center;
      justify-content: center;
      ${theme.breakpoints.down("md")} {
        margin-bottom: ${hasTitleComponent ? theme.spacing(4) : 0};
      }
    `,
    backAction: css`
      position: absolute;
      left: ${theme.spacing(4)};
      padding: 0;
      min-width: auto;
      background-color: transparent;

      :hover {
        background-color: transparent;
      }
    `,
    backArrow: css`
      transform: rotate(180deg);
      height: ${theme.shape.iconSize.small}px;
      width: ${theme.shape.iconSize.small}px;
      color: ${theme.palette.text.primary};
    `,
    titleComponent: css`
      text-align: center;
      mouse-events: none;
    `,
    closeIcon: css`
      right: ${theme.spacing(4)};
      top: ${theme.spacing(4)};
      position: absolute;
      height: ${theme.shape.iconSize.medium}px;
      width: ${theme.shape.iconSize.medium}px;
      margin-left: auto;
      min-width: 0;
      padding: 0;
      background-color: transparent;

      :hover {
        background-color: transparent;
      }
    `,
    contentWrapper: css`
      padding-bottom: ${theme.spacing(4)};
      padding-left: ${noHorizontalPadding ? 0 : theme.spacing(4)};
      padding-right: ${noHorizontalPadding ? 0 : theme.spacing(4)};
      ${theme.breakpoints.down("md")} {
        padding-bottom: ${theme.spacing(4)};
        padding-left: ${noHorizontalPadding ? 0 : theme.spacing(4)};
        padding-right: ${noHorizontalPadding ? 0 : theme.spacing(4)};
      }
    `,
  }
}
