/** @jsxImportSource @emotion/react */
import AppBar from "@mui/material/AppBar"
import React, { FC } from "react"

import ConnectButton from "../ConnectButton"
import { Toolbar } from "../Toolbar"
import Title from "./Title"
import { useStyles } from "./styles"
import { Box, useMediaQuery, useTheme } from "@mui/material"

const Header: FC = () => {
  const styles = useStyles()
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"))
  return (
    <AppBar position="relative" css={styles.appBar} color="transparent">
      <Toolbar css={styles.toolbar}>
        <Title />
        <div style={{ flex: 1 }} />
        {lgUp && <ConnectButton />}
      </Toolbar>
    </AppBar>
  )
}

export default Header
