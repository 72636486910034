import { css } from "@emotion/react"
import { useTheme } from "@mui/material"

export const useStyles = () => {
  const theme = useTheme()

  return {
    container: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      ${theme.breakpoints.down("sm")} {
        display: block;
      }
    `,
    column: css`
      display: flex;
      align-items: center;

      ${theme.breakpoints.down("sm")} {
        &:first-of-type {
          margin-bottom: ${theme.spacing(1)};
        }
      }
    `,
    icon: css`
      width: ${theme.shape.iconSize.small}px;
      height: ${theme.shape.iconSize.small}px;
      margin-top: -2px;
      margin-right: ${theme.spacing(2)};
    `,
    getLabel: ({ invertTextColors }: { invertTextColors: boolean }) => css`
      color: ${invertTextColors ? theme.palette.text.primary : theme.palette.text.secondary};
    `,
    getContent: ({ invertTextColors, hasIcon }: { invertTextColors: boolean; hasIcon: boolean }) => css`
      color: ${invertTextColors ? theme.palette.text.secondary : theme.palette.text.primary};

      ${hasIcon &&
      css`
        margin-left: ${theme.spacing(7)};
      `}
    `,
  }
}
