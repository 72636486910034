/** @jsxImportSource @emotion/react */
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Modal as MUIModal,
  ModalProps as MUIModalProps,
} from "@mui/material"
import Backdrop from "@mui/material/Backdrop"
import Fade from "@mui/material/Fade"
import React, { FC, ReactElement } from "react"

import { Icon } from "../Icon"
import { useModalStyles } from "./styles"

export interface ModalProps extends Omit<MUIModalProps, "title" | "open"> {
  className?: string
  isOpen: boolean
  handleClose: () => void
  handleBackAction?: () => void
  title?: string | ReactElement | ReactElement[]
  noHorizontalPadding?: boolean
}

export const Modal: FC<ModalProps> = ({
  className,
  children,
  handleClose,
  handleBackAction,
  isOpen,
  title,
  noHorizontalPadding,
  ...otherModalProps
}) => {
  const s = useModalStyles({ hasTitleComponent: Boolean(title), noHorizontalPadding })
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{ sx: { p: 0, margin: 0, width: "calc(100% - 32px)" } }}
    >
      <DialogTitle
        sx={{
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
          display: "flex",
          justifyContent: "center",
          pt: 0,
          py: 5,
          mb: 4,
        }}
      >
        {!!handleBackAction && (
          <Button css={s.backAction} disableRipple onClick={handleBackAction}>
            <Icon css={s.backArrow} name="arrowRight" />
          </Button>
        )}
        <div style={{ margin: "0 auto" }}>{title}</div>
        <Button css={s.closeIcon} disableRipple onClick={handleClose}>
          <Icon name="close" />
        </Button>
      </DialogTitle>

      <DialogContent sx={{ px: { xs: 4, md: 7 }, pb: { xs: 4, md: 7 } }}>{children}</DialogContent>
    </Dialog>
  )
}
