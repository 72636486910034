import { Stack, useTheme } from "@mui/material"
import _uniqueId from "lodash/uniqueId"
import Image from "next/image"
import React, { FC, useRef } from "react"

import * as icons from "./icons"

export type IconName = string /* keyof typeof icons; */ // TODO: improve type

export interface IconProps {
  name: IconName
  size?: string
  color?: string
  className?: string
  onClick?: (e: MouseEvent) => void
}

export const Icon: FC<IconProps> = ({ name, size, color, ...otherProps }) => {
  const idRef = useRef<string>(_uniqueId())

  const theme = useTheme()
  const sanitizedSize = size ?? theme.shape.iconSize.small
  const sanitizedColor = color ?? theme.palette.text.secondary
  // Because "name" could come from fetched data, we use a default icon in case
  // the one requested isn't found

  if (name in icons) {
    const Component = icons[name as keyof typeof icons]
    return (
      <Component
        className="icon"
        width={sanitizedSize}
        height={sanitizedSize}
        color={sanitizedColor}
        id={idRef.current}
        {...otherProps}
      />
    )
  }
  if (`src_${name}` in icons) {
    return (
      <Image
        className="icon"
        src={icons[`src_${name}` as keyof typeof icons] as string}
        alt=""
        style={{ width: size ?? undefined, height: size ?? undefined, color: color ?? undefined }}
        id={idRef.current}
      />
    )
  }

  const Component = icons.mask
  return (
    <Component
      className="icon"
      width={sanitizedSize}
      height={sanitizedSize}
      color={sanitizedColor}
      id={idRef.current}
      {...(otherProps as any)}
    />
  )
}
