import { css } from "@emotion/react"
import { useTheme } from "@mui/material"

export const useStyles = () => {
  const theme = useTheme()

  return {
    appBar: css`
      background-image: none;
      background-color: transparent;
      box-shadow: none;
      padding: ${theme.spacing(5, 0)};
    `,
    toolbar: css`
      padding: ${theme.spacing(0, 1)} !important;
      justify-content: space-between;
      display: flex;
      min-height: 0px !important;
    `,
    ctaContainer: css`
      display: flex;
      align-items: center;
      margin-left: auto;

      ${theme.breakpoints.down("lg")} {
        display: none;
      }
    `,
  }
}
