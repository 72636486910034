/** @jsxImportSource @emotion/react */
import BigNumber from "bignumber.js"
import { FC, useMemo, useState } from "react"
import { Token } from "types"

import { TertiaryButton } from "../Button"
import { TextField, TextFieldProps } from "../TextField"

const sanitizedInput = (input: string): string => input.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1") // Remove non-numeric characters and extra decimal points

const formatNumberWithCommas = (input: string): string => {
  const parts = sanitizedInput(input).split(".")
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Add commas to integer part

  if (parts.length > 1) {
    const decimalPart = parts[1]
    return `${integerPart}.${decimalPart}`
  }
  return integerPart
}

// Note: although we display all the values in tokens (equivalent of ether for
// the given token) to the user, the underlying values (maxWei, value) are
// expressed in wei to make them easier to use with contracts
export interface TokenTextFieldProps extends Omit<TextFieldProps, "onChange" | "value" | "max" | "min"> {
  token: Token
  value: string
  onChange: (newValue: string) => void
  rightMaxButton?: {
    label: string
    valueOnClick: string
    active?: boolean
  }
  displayTokenIcon?: boolean
  max?: string
}

export const TokenTextField: FC<TokenTextFieldProps> = ({
  token,
  rightMaxButton,
  onChange,
  disabled,
  max,
  displayTokenIcon = true,
  ...otherProps
}) => {
  const step = useMemo(() => {
    const tmpOneTokenInWei = new BigNumber(10).pow(token.decimals)
    const tmpOneWeiInTokens = new BigNumber(1).dividedBy(tmpOneTokenInWei)

    return tmpOneWeiInTokens.toFixed()
  }, [token.decimals])

  const setMaxValue = (newValue: string) => {
    if (onChange) {
      onChange(newValue)
    }
  }

  const handleChange: TextFieldProps["onChange"] = ({ currentTarget: { value } }) => {
    onChange(sanitizedInput(value))
  }

  const formattedValue = useMemo(() => formatNumberWithCommas(otherProps.value), [otherProps.value])

  return (
    <TextField
      placeholder="0.00"
      min={0}
      max={max}
      step={step}
      onChange={handleChange}
      // type="number"
      leftIconSrc={displayTokenIcon ? token : undefined}
      rightAdornment={
        rightMaxButton ? (
          <TertiaryButton
            onClick={() => setMaxValue(rightMaxButton.valueOnClick)}
            style={rightMaxButton.active ? { background: "#6100FF", color: "#fff" } : { color: "#666" }}
            small
            disabled={disabled}
          >
            {rightMaxButton.label}
          </TertiaryButton>
        ) : undefined
      }
      disabled={disabled}
      {...otherProps}
      value={formattedValue}
    />
  )
}
