import { Dialog, Divider, Link, Typography, Stack } from "@mui/material"
import React, { useEffect, useState } from "react"

import { Button } from "components/Button"
import { FLUX_COOKIES_URL } from "../../../constants"

import useGAMeasurement from "../useGAMeasurement"
import ls from "./local-storage"
import { cookieOptions } from "./strings"
import { CookieAccordion, CookiePopover } from "./styles"

const CookiesModule = () => {
  const [open, setOpen] = useState(false)

  const [openAccordian, setOpenAccordian] = useState<string | undefined>(undefined)

  // default values are necessary cookies
  const [cookiePrefs, setCookiePrefs] = useState<any>({
    has_accepted_functional: false,
    has_accepted_analytics: false,
    initial: true,
  })

  const allowAll = () => {
    const all = {
      has_accepted_functional: true,
      has_accepted_analytics: true,
      has_accepted_targeting: true,
      date: Date.now(),
    }
    ls.set("cookie-preferences", all)
    setCookiePrefs(all)
    setOpen(false)
  }

  // accepted and necessary (defaults to necessary)
  const allowSelected = () => {
    const selected = { ...cookiePrefs, date: Date.now(), initial: false }
    ls.set("cookie-preferences", selected)
    setCookiePrefs(selected)
    setOpen(false)
  }

  // necessary only
  const necessaryOnly = () => {
    const necessary = {
      has_accepted_functional: false,
      has_accepted_analytics: false,
      has_accepted_targeting: false,
      date: Date.now(),
    }
    ls.set("cookie-preferences", necessary)
    setCookiePrefs(necessary)
    setOpen(false)
  }

  const onChange = (id: string, checked: boolean) => {
    setCookiePrefs({ ...cookiePrefs, [id]: checked })
  }

  useEffect(() => {
    const saved = ls.get("cookie-preferences")
    if (saved?.date) {
      setCookiePrefs(saved)
    }
  }, [])

  const hasSetPreferences = !cookiePrefs.initial

  const onClose = () => setOpen(false)

  useGAMeasurement(cookiePrefs.has_accepted_analytics)

  return (
    <>
      {!hasSetPreferences && (
        <CookiePopover data-testid="cookie-popover">
          <Typography>
            This site uses cookies to personalise your experience. Learn more about our{" "}
            <Link href={FLUX_COOKIES_URL} target="_blank">
              Cookies Policy
            </Link>
            .
          </Typography>
          <Stack mt={3} direction={{ xs: "column", sm: "row" }} spacing={1.5}>
            <Button variant="primary" onClick={allowAll} data-testid="allow-all-cookies-button">
              Allow All
            </Button>
            <Button variant="secondary" onClick={necessaryOnly}>
              Allow Necessary
            </Button>
            <Button variant="secondary" onClick={() => setOpen(true)} data-testid="manage-cookies-button">
              Manage
            </Button>
          </Stack>
        </CookiePopover>
      )}

      <Dialog
        PaperProps={{ variant: "outlined", sx: { padding: 0 } }}
        open={open}
        maxWidth="sm"
        onClose={onClose}
        data-testid="cookies-prefs-dialog"
      >
        <Typography align="center" variant="h6" pt={4} pb={1}>
          Cookie Preferences
        </Typography>
        <Typography align="center" variant="body2" pb={3}>
          This site uses cookies to personalise your experience.{" "}
          <Link href={FLUX_COOKIES_URL} target="_blank" rel="noopenner noreferrer">
            Learn More
          </Link>
        </Typography>

        <Divider sx={{ mb: 0 }} />

        {cookieOptions.map((cookieOption, i) => (
          <CookieAccordion
            key={cookieOption.key}
            alwaysOn={cookieOption.alwaysOn}
            name={cookieOption.name}
            details={cookieOption.details}
            onExpand={() =>
              cookieOption.key === openAccordian ? setOpenAccordian(undefined) : setOpenAccordian(cookieOption.key)
            }
            expanded={cookieOption.key === openAccordian}
            enabled={!!cookiePrefs?.[cookieOption.key]}
            onChange={(checked) => onChange(cookieOption.key, checked)}
          />
        ))}

        <Stack direction={{ xs: "column", sm: "row" }} spacing={1} p={2}>
          <Button variant="secondary" onClick={allowSelected} data-testid="confirm-cookies-prefs-button">
            Confirm my choices
          </Button>
          <Button variant="primary" onClick={allowAll} data-testid="allow-all-cookies-button">
            Allow All
          </Button>
        </Stack>
      </Dialog>
    </>
  )
}

export default CookiesModule
