import { ChainIds, Token } from "types"

import fDai from "assets/img/tokens/fDai.svg"
import fFrax from "assets/img/tokens/fFRAX.svg"
import fOusg from "assets/img/tokens/fOusg.svg"
import fUsdc from "assets/img/tokens/fUsdc.svg"
import fUsdt from "assets/img/tokens/fUsdt.svg"

import FTOKEN_ADDRESSES from "./contracts/addresses/fTokens.json"

export const MAINNET_FTOKENS = {
  dai: {
    id: "dai",
    symbol: "fDAI",
    address: FTOKEN_ADDRESSES.dai[ChainIds.MAINNET],
    decimals: 8,
    asset: fDai,
  } as Token,
  usdc: {
    id: "usdc",
    symbol: "fUSDC",
    address: FTOKEN_ADDRESSES.usdc[ChainIds.MAINNET],
    decimals: 8,
    asset: fUsdc,
  } as Token,
  usdt: {
    id: "usdt",
    symbol: "fUSDT",
    address: FTOKEN_ADDRESSES.usdt[ChainIds.MAINNET],
    decimals: 8,
    asset: fUsdt,
  } as Token,
  frax: {
    id: "frax",
    symbol: "fFRAX",
    address: FTOKEN_ADDRESSES.frax[ChainIds.MAINNET],
    decimals: 8,
    asset: fFrax,
  } as Token,
  ousg: {
    id: "OUSG",
    symbol: "fOUSG",
    address: FTOKEN_ADDRESSES?.ousg?.[ChainIds.MAINNET],
    decimals: 8,
    asset: fOusg,
  } as Token,
}
