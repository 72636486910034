/** @jsxImportSource @emotion/react */
import { Stack } from "@mui/material"
import Typography from "@mui/material/Typography"
import React, { FC, ReactElement } from "react"
import { ChainIds, Token } from "types"

import { PrimaryButton } from "../Button"
import { LabeledInlineContentProps } from "../LabeledInlineContent"
import { useNetwork, useSwitchNetwork } from "wagmi"

export interface EnableTokenUiProps {
  token: Token
  title: string | ReactElement
  isTokenEnabled: boolean
  isInitialLoading?: boolean
  isEnableTokenLoading?: boolean
  tokenInfo?: LabeledInlineContentProps[]
  disabled?: boolean
  toast: any
  bypass?: boolean
}

export interface EnableTokenProps
  extends Pick<EnableTokenUiProps, "tokenInfo" | "disabled" | "title" | "token" | "toast" | "bypass"> {
  spenderAddress: string
  children: React.ReactNode
}

export const SwitchToActiveNetworkButton: FC = () => {
  const { switchNetwork } = useSwitchNetwork()
  return <PrimaryButton onClick={() => switchNetwork?.(ChainIds.MAINNET)}>Switch Network</PrimaryButton>
}

export const EnableToken: FC<EnableTokenProps> = ({ token, spenderAddress, ...rest }) => {
  const { chain } = useNetwork()
  return chain?.id === ChainIds.MAINNET ? (
    <>{rest.children}</>
  ) : (
    <Stack spacing={4} py={16} alignItems="center">
      <Typography component="h6" variant="h6">
        {rest.title}
      </Typography>
      <SwitchToActiveNetworkButton />
    </Stack>
  )
}

export default EnableToken
