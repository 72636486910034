import { useRouter } from "next/router"
import React, { FC, useEffect } from "react"

export const ResetScrollOnRouteChange: FC = () => {
  const { pathname } = useRouter()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
