import BigNumber from "bignumber.js"
import { Market } from "types"
import { unsafelyGetToken } from "utilities/misc"
import { restService } from "utilities/restService"

export type GetMarketsResponse = Market[]

export interface GetMarketsOutput {
  markets: Market[]
}

const getMarkets = async (chainId?: number): Promise<GetMarketsOutput> => {
  const response = await restService<GetMarketsResponse>({
    endpoint: "/markets",
    method: "GET",
    chainId,
  })
  if ("result" in response && response.result === "error") {
    throw new Error(response.message)
  }
  let markets: Market[] = []
  if (response && response.data) {
    markets = response.data.filter((market) => !!unsafelyGetToken(market.underlyingSymbol.toLowerCase()))

    markets = markets.map((activeMarket) => ({
      ...activeMarket,
      id: activeMarket.underlyingSymbol.toLowerCase(),
      tokenPrice: new BigNumber(activeMarket.tokenPrice),
      borrowApy: new BigNumber(activeMarket.borrowApy),
      supplyApy: new BigNumber(activeMarket.supplyApy),
      totalBorrowsUsd: activeMarket.totalBorrowsUsd,
      totalSupplyUsd: activeMarket.totalSupplyUsd,
      underlyingName: activeMarket.name,
      // turn collataral factor into a percentage, from decimal
    }))
  }

  return { markets }
}

export default getMarkets
