import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import ReactGA from "react-ga4"

const useGAMeasurement = (accepted: boolean) => {
  const [didInit, setDidInit] = useState(false)
  const { pathname } = useRouter()
  useEffect(() => {
    if (accepted && !didInit && process.env.NEXT_PUBLIC_GA_ID !== undefined) {
      ReactGA.initialize(process.env.NEXT_PUBLIC_GA_ID!)
      // init clearbit
      const cbScript = document.createElement("script")
      const body = document.getElementsByTagName("body")[0]
      cbScript.src = "https://tag.clearbitscripts.com/v1/pk_368e1fe3174212f63883ec2b5b9167ef/tags.js"
      body.appendChild(cbScript)
      setDidInit(true)
    }
    if (accepted) {
      ReactGA.send({ hitType: "pageview", page: pathname })
    }
  }, [accepted, pathname])
}

export default useGAMeasurement
