/** @jsxImportSource @emotion/react */
import { useRouter } from "next/router"
import React, { FC } from "react"

import { Icon } from "../../../../Icon"
import { useStyles } from "./styles"

const BackButton: FC<{ children: React.ReactNode }> = ({ children }) => {
  const styles = useStyles()
  const router = useRouter()

  return (
    <div onClick={() => router.back()} css={styles.container}>
      <Icon name="chevronLeft" css={styles.icon} />

      {children}
    </div>
  )
}

export default BackButton
