import { ChainIds, Token } from "types"

import bnb from "assets/img/tokens/bnb.svg"
import dai from "assets/img/tokens/dai.svg"
import eth from "assets/img/tokens/eth.svg"
import frax from "assets/img/tokens/frax.svg"
import ousg from "assets/img/tokens/ousg.svg"
import usdc from "assets/img/tokens/usdc.svg"
import usdt from "assets/img/tokens/usdt.svg"

import TOKEN_ADDRESSES from "./contracts/addresses/tokens.json"

// token names must be lowercase version
export const MAINNET_TOKENS = {
  dai: {
    id: "dai",
    symbol: "DAI",
    decimals: 18,
    address: TOKEN_ADDRESSES.dai[ChainIds.MAINNET],
    asset: dai,
    name: "Dai",
  } as Token,
  usdc: {
    id: "usdc",
    symbol: "USDC",
    decimals: 6,
    address: TOKEN_ADDRESSES.usdc[ChainIds.MAINNET],
    asset: usdc,
    name: "USD Coin",
  } as Token,
  usdt: {
    id: "usdt",
    symbol: "USDT",
    decimals: 6,
    address: TOKEN_ADDRESSES.usdt[ChainIds.MAINNET],
    asset: usdt,
    name: "Tether USD",
  } as Token,
  frax: {
    id: "frax",
    symbol: "FRAX",
    decimals: 18,
    address: TOKEN_ADDRESSES.frax[ChainIds.MAINNET],
    asset: frax,
    name: "Frax",
  } as Token,
  ousg: {
    id: "ousg",
    symbol: "OUSG",
    decimals: 18,
    address: TOKEN_ADDRESSES.ousg[ChainIds.MAINNET],
    asset: ousg,
    name: "Ondo Short-Term U.S. Government Bond Fund",
  } as Token,
}

export { eth, bnb }
