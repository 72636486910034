/** @jsxImportSource @emotion/react */
import { FC } from "react"
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { useTranslation } from "translation"
import { formatToReadablePercentage } from "utilities/formatting"

import TooltipContent from "../TooltipContent"
import { useStyles as useSharedStyles } from "../styles"
import { useStyles as useLocalStyles } from "./styles"

export interface InterestRateItem {
  utilizationRate: number
  borrowApyPercentage: number
  supplyApyPercentage: number
}

export interface InterestRateChartProps {
  data: InterestRateItem[]
  currentUtilizationRate?: number
  className?: string
}

export const InterestRateChart: FC<InterestRateChartProps> = ({ className, currentUtilizationRate, data }) => {
  const sharedStyles = useSharedStyles()
  const localStyles = useLocalStyles()

  const { t } = useTranslation()
  const maxDomain = Math.ceil(data?.[data.length - 1]?.borrowApyPercentage || 100)

  return (
    <div style={{ width: "100%", height: "300px", position: "relative" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data} margin={sharedStyles.chartMargin}>
            <XAxis
              dataKey="utilizationRate"
              axisLine={false}
              tickLine={false}
              tickFormatter={formatToReadablePercentage}
              stroke={sharedStyles.accessoryColor}
              tickMargin={sharedStyles.tickMargin}
              tickCount={5}
              type="number"
              style={sharedStyles.axis}
            />
            <YAxis
              domain={[0, maxDomain]}
              allowDataOverflow
              axisLine={false}
              tickLine={false}
              tickFormatter={formatToReadablePercentage}
              tickMargin={sharedStyles.tickMargin}
              stroke={sharedStyles.accessoryColor}
              style={sharedStyles.axis}
              tickCount={10}
            />
            <Tooltip
              isAnimationActive={false}
              cursor={sharedStyles.cursor}
              content={({ payload }) =>
                payload && payload[0] ? (
                  <TooltipContent
                    items={[
                      {
                        label: t("interestRateChart.tooltipItemLabels.utilizationRate"),
                        value: formatToReadablePercentage((payload[0].payload as InterestRateItem).utilizationRate),
                      },
                      {
                        label: t("interestRateChart.tooltipItemLabels.borrowApy"),
                        value: formatToReadablePercentage((payload[0].payload as InterestRateItem).borrowApyPercentage),
                      },
                      {
                        label: t("interestRateChart.tooltipItemLabels.supplyApy"),
                        value: formatToReadablePercentage((payload[0].payload as InterestRateItem).supplyApyPercentage),
                      },
                    ]}
                  />
                ) : null
              }
            />
            <CartesianGrid vertical={false} stroke={sharedStyles.gridLineColor} />
            {/* <Line
            type="monotone"
            dataKey="utilizationRate"
            stroke={localStyles.referenceLineColor}
            strokeWidth={sharedStyles.lineStrokeWidth}
            isAnimationActive={false}
            activeDot={localStyles.lineActiveDot}
            dot={false}
          /> */}

            <Line
              type="monotone"
              dataKey="borrowApyPercentage"
              stroke={localStyles.lineBorrowApyColor}
              strokeWidth={sharedStyles.lineStrokeWidth}
              isAnimationActive={false}
              activeDot={localStyles.lineActiveDot}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="supplyApyPercentage"
              stroke={localStyles.lineSupplyApyColor}
              strokeWidth={sharedStyles.lineStrokeWidth}
              activeDot={localStyles.lineActiveDot}
              isAnimationActive={false}
              dot={false}
            />
            <ReferenceLine
              x={currentUtilizationRate}
              stroke={localStyles.referenceLineColor}
              strokeWidth={2}
              // Note: we can not use the spread operator to extend
              // styles.referenceLineLabel because its type is not accepted for
              // that
              label={Object.assign(localStyles.referenceLineLabel || {}, {
                value: t("interestRateChart.currentUtilizationRateLabelValue", {
                  percentage: formatToReadablePercentage(currentUtilizationRate),
                }),
                position: (currentUtilizationRate || 0) > 50 ? "insideTopRight" : "insideTopLeft",
              })}
              alwaysShow
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
