import { CssBaseline } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { inject } from "@vercel/analytics"
import BigNumber from "bignumber.js"
import { ResetScrollOnRouteChange } from "components"
import mainTheme from "muiTheme"
import type { AppProps } from "next/app"
import Head from "next/head"
import React, { useEffect } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { ToastContainer } from "react-toastify"

import CookiesModule from "components/Cookies/CookiesModule"
import Layout from "components/Layout"
import { AuthProvider } from "context/AuthContext"
import { SuccessfulTransactionModalProvider } from "context/SuccessfulTransactionModalContext"

import { connectorsForWallets, getDefaultWallets, lightTheme, RainbowKitProvider, Theme } from "@rainbow-me/rainbowkit"
import { configureChains, createConfig, WagmiConfig } from "wagmi"
import { mainnet, polygon, optimism, arbitrum, base, zora } from "wagmi/chains"
import { alchemyProvider } from "wagmi/providers/alchemy"
import { publicProvider } from "wagmi/providers/public"
import { merge } from "lodash"
import { CacheProvider } from "@emotion/react"
import createCache from "@emotion/cache"

import {
  trustWallet,
  ledgerWallet,
  metaMaskWallet,
  walletConnectWallet,
  rainbowWallet,
  coinbaseWallet,
  rabbyWallet,
  braveWallet,
} from "@rainbow-me/rainbowkit/wallets"

import "assets/styles/App.scss"
import "assets/styles/normalize.scss"
import "@rainbow-me/rainbowkit/styles.css"

// Add toJSON method to BigInt
;(BigInt.prototype as any).toJSON = function () {
  return this.toString()
}

// WalletConnect projectId
const projectId = "d21b7028bc192d6e9e1d32449f7e0978"

const myCustomTheme: Theme = merge(lightTheme(), {
  blurs: {
    // modalOverlay: '...',
  },
  colors: {
    accentColor: "black",
    accentColorForeground: "white",
    // actionButtonBorder: '...',
    // actionButtonBorderMobile: '...',
    // actionButtonSecondaryBackground: '...',
    // closeButton: '...',
    // closeButtonBackground: '...',
    // connectButtonBackground: '...',
    // connectButtonBackgroundError: '...',
    // connectButtonInnerBackground: '...',
    // connectButtonText: '...',
    // connectButtonTextError: '...',
    // connectionIndicator: '...',
    // downloadBottomCardBackground: '...',
    // downloadTopCardBackground: '...',
    // error: '...',
    // generalBorder: '...',
    // generalBorderDim: '...',
    // menuItemBackground: '...',
    // modalBackdrop: '...',
    // modalBackground: '...',
    // modalBorder: '...',
    // modalText: '...',
    // modalTextDim: '...',
    // modalTextSecondary: '...',
    // profileAction: '...',
    // profileActionHover: '...',
    // profileForeground: '...',
    // selectedOptionBorder: '...',
    // standby: '...',
  },
  fonts: {
    // body: '...',
  },
  radii: {
    // actionButton: '...',
    connectButton: "24px",
    // menuButton: '...',
    // modal: '...',
    // modalMobile: '...',
  },
  // Remove shadows
  shadows: {
    connectButton: "",
    dialog: "",
    profileDetailsAction: "",
    selectedOption: "",
    selectedWallet: "",
    walletLogo: "",
  },
})

const { chains, publicClient } = configureChains(
  [mainnet /* , polygon, optimism, arbitrum, base, zora */],
  [alchemyProvider({ apiKey: process.env.NEXT_PUBLIC_ALCHEMY_MAINNET_KEY as string }), publicProvider()],
)

const connectors = connectorsForWallets([
  {
    groupName: "Popular",
    wallets: [
      metaMaskWallet({ chains, projectId }),
      walletConnectWallet({ chains, projectId }),
      coinbaseWallet({ chains, appName: "Flux Finance" }),
      rainbowWallet({ chains, projectId }),
      rabbyWallet({ chains }),
    ],
  },
  {
    groupName: "Other",
    wallets: [ledgerWallet({ chains, projectId }), trustWallet({ chains, projectId }), braveWallet({ chains })],
  },
])

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
})

// Initialize BigNumber format
BigNumber.config({
  FORMAT: {
    decimalSeparator: ".",
    groupSize: 3,
    groupSeparator: ",",
  },
})

if (process.env.VERCEL_ENV === "production") {
  console.log = () => {}
  console.error = () => {}
  console.warn = () => {}
}

const emotionCache = createCache({ key: "css", prepend: true })

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Set a stale time of 10 seconds so query results don't get wiped out of
      // the cache instantly after their hook unmounts (see documentation for
      // more info: https://react-query.tanstack.com/guides/important-defaults)
      staleTime: 0,
      cacheTime: 14000,
      refetchInterval: 7000,
    },
  },
})

export default function App({ Component, pageProps }: AppProps) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", { noSsr: true })
  useEffect(inject, []) // Vercel Analytics
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <link rel="shortcut icon" href={prefersDarkMode ? "/favicon-light.png" : "favicon.png"} />
      </Head>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider modalSize="wide" theme={myCustomTheme} chains={chains}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={mainTheme}>
              <CssBaseline />
              <SuccessfulTransactionModalProvider>
                <AuthProvider>
                  {/* <ReactQueryDevtools initialIsOpen /> */}
                  <ToastContainer />
                  <Layout>
                    <ResetScrollOnRouteChange />
                    <Component {...pageProps} />
                  </Layout>
                  <CookiesModule />
                </AuthProvider>
              </SuccessfulTransactionModalProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </CacheProvider>
  )
}
