import * as React from "react"
import { SVGProps } from "react"

const SvgOpen = ({ width, height }: SVGProps<SVGSVGElement>) => (
  <svg width={width || "28"} height={height || "28"} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0625 8.3125H19.6875M19.6875 8.3125V17.9375M19.6875 8.3125L8.3125 19.6875"
      stroke="white"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
  </svg>
)

export default SvgOpen
