/** @jsxImportSource @emotion/react */
import { Box, Container } from "@mui/material"
import { useRouter } from "next/router"
import React, { FC, useEffect, useMemo, useState } from "react"

import bg from "assets/img/homepageBg.svg"

import Header from "./Header"
import Sidebar from "./Sidebar"
import { useStyles } from "./styles"
import { Theme } from "@mui/material"

const Layout: FC<{ children: React.ReactNode }> = ({ children }) => {
  const styles = useStyles()
  const router = useRouter()

  // set a boolean to true if scrolled 10 pixels
  const [scrolled, setScrolled] = useState(router.pathname !== "/")

  const onStop = () => {
    const offset = window.scrollY
    const half = window.innerHeight / 8
    if (offset > half) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  // listen for the scroll stop
  useEffect(() => {
    window.addEventListener("scroll", onStop)
    return () => {
      window.removeEventListener("scroll", onStop)
    }
  }, [])

  useEffect(() => {
    onStop()
  }, [router.pathname])

  const isLight = useMemo(() => scrolled || router.pathname !== "/", [scrolled, router.pathname])

  return (
    <Box
      sx={
        isLight
          ? {
              transition: "background-color 0.3s ease-in-out",
              backgroundBlendMode: "lighten",
              background: (theme: Theme) => theme.palette.background.default,
            }
          : {
              transition: "background-color 0.3s ease-in-out",
              backgroundBlendMode: "lighten",
              // '#16171A',
              background: {
                xs: `center center / auto 100vh fixed no-repeat url(${bg.src}), #16171A`,
                md: `bottom 0vh left 0vh / 100% auto fixed no-repeat url(${bg.src}), #16171A`,
                // lg: `top left 10vw  / auto 100% fixed no-repeat url(${bg}), #16171A`,
                // xl: `top right  / auto 100% fixed no-repeat url(${bg}), #16171A`,
              },
              color: "white",
              ".icon": { filter: "invert(1)" },
              ".MuiListItemButton-root .MuiTypography-root": { color: "white" },
              "#connect-button": { background: "white", color: "black" },
              "#burger": { filter: "invert(1)" },
            }
      }
    >
      <Container maxWidth="xl" css={styles.layout}>
        <Sidebar />
        <Box display="flex" flexDirection="column" flex="1">
          <Container component="header" maxWidth="xl">
            <Header />
          </Container>
          <Container component="main" css={styles.main} maxWidth="xl">
            {children}
          </Container>
        </Box>
      </Container>
    </Box>
  )
}

export default Layout
