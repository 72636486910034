/** @jsxImportSource @emotion/react */
import { Paper, Skeleton, Stack, Typography } from "@mui/material"
import Link from "next/link"

import { Delimiter } from "../Delimiter"
import { useStyles } from "./styles"
import { TableRowProps } from "./types"
import { FC, MouseEvent } from "react"

interface TableCardProps {
  rows: TableRowProps[][]
  rowKeyIndex: number
  columns: {
    key: string
    label: string
    orderable: boolean
    hideCard?: boolean
  }[]
  className?: string
  rowOnClick?: (e: MouseEvent<HTMLDivElement>, row: TableRowProps[]) => void
  getRowHref?: (row: TableRowProps[]) => string
  isFetching?: boolean
  emptyTableMessage?: string
}

const TableCards: FC<TableCardProps> = ({
  rows,
  rowKeyIndex,
  rowOnClick,
  getRowHref,
  columns,
  className,
  isFetching,
  emptyTableMessage,
}) => {
  const styles = useStyles()

  return (
    <div className={className} css={styles.tableWrapperMobileWrap} key="cards">
      {isFetching && !rows?.length && (
        <div>
          {[1, 2, 3].map((index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width="100%"
              height="200px"
              animation="pulse"
              css={styles.tableWrapperMobile({
                clickable: !!(rowOnClick || getRowHref),
                loading: true,
              })}
            />
          ))}
        </div>
      )}
      {!isFetching && !rows?.length && (
        <Stack alignItems="center" my={10}>
          <Typography variant="body1" color="textSecondary">
            {emptyTableMessage || "No records available"}
          </Typography>
        </Stack>
      )}
      {rows.map((row, idx) => {
        const rowKey = `${row[rowKeyIndex].value.toString()}-${idx}-cards`
        const [titleColumn, ...otherColumns] = columns
        const titleCell = row.find((cell) => titleColumn.key === cell.key)
        return (
          <Paper
            key={rowKey}
            css={styles.tableWrapperMobile({ clickable: !!(rowOnClick || getRowHref) })}
            onClick={rowOnClick && ((e: MouseEvent<HTMLDivElement>) => rowOnClick(e, row))}
            component={
              getRowHref
                ? ({ children, ...props }: any) => (
                    <Link href={getRowHref(row)}>
                      <div {...props}>{children} </div>
                    </Link>
                  )
                : "div"
            }
          >
            <div css={styles.rowTitleMobile}>{titleCell?.render?.()}</div>

            <div className="table__table-cards__card-content" css={styles.rowWrapperMobile}>
              {otherColumns.map((column) => {
                const currentCell = row.find((cell) => column.key === cell.key)
                if (column.hideCard || !currentCell?.render) return null
                return (
                  <div
                    key={`${rowKey}-${currentCell?.key}`}
                    css={styles.cellMobile}
                    style={currentCell.colSpan ? { gridColumn: "1 / span 2" } : {}}
                  >
                    <Typography variant="body2" css={styles.columnLabelMobile}>
                      {column?.label}
                    </Typography>
                    <div css={styles.cellValueMobile}>{currentCell?.render()}</div>
                  </div>
                )
              })}
            </div>
          </Paper>
        )
      })}
    </div>
  )
}

export default TableCards
