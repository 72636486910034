/** @jsxImportSource @emotion/react */
import { Typography } from "@mui/material"
import React, { FC } from "react"

import { useStyles } from "./styles"

export interface LayeredValuesProps {
  topValue: string | number
  bottomValue: string | number
  className?: string
}

export const LayeredValues: FC<LayeredValuesProps> = ({ topValue, bottomValue, className }) => {
  const styles = useStyles()

  return (
    <div css={styles.container} className={className}>
      <Typography variant="body1" css={styles.topValue}>
        {topValue}
      </Typography>

      <Typography variant="small1">{bottomValue}</Typography>
    </div>
  )
}
export default LayeredValues
