/** @jsxImportSource @emotion/react */
import { Checkbox, FormControlLabel, SxProps, Typography } from "@mui/material"
import React, { ReactNode } from "react"

export const LabelledCheckbox = ({
  setChecked,
  disabled,
  children,
  testId,
  sx,
}: {
  setChecked: (s: boolean) => void
  disabled?: boolean
  children: ReactNode
  testId?: string
  sx?: SxProps
}) => (
  <FormControlLabel
    sx={sx}
    labelPlacement="end"
    control={<Checkbox onChange={(e) => setChecked(e.target.checked)} disabled={disabled} color="primary" />}
    label={
      <div style={{ textAlign: "left" }}>
        <Typography variant="body1">{children}</Typography>
      </div>
    }
    data-testid={testId}
  />
)
