/** @jsxImportSource @emotion/react */
import React, { FC } from "react"

import { Spinner as SpinnerAnimation } from "../LottieAnimation"
import { useStyles } from "./styles"

interface SpinnerProps {
  variant?: "large" | "small"
  autoplay?: boolean
  className?: string
}

export const Spinner: FC<SpinnerProps> = ({ variant = "large", autoplay = true, className }) => {
  const styles = useStyles({ variant })
  return (
    <div css={styles.container} className={className}>
      <SpinnerAnimation autoplay={autoplay} css={styles.spinner} />
    </div>
  )
}
