import { css } from "@emotion/react"
import { Theme, useTheme, darken, lighten } from "@mui/material"

import { Variant } from "./types"

export const useStyles = ({ fullWidth, variant, small }: { fullWidth: boolean; variant: Variant; small: boolean }) => {
  const theme = useTheme()

  const getButtonVariantCss = (refVariant: Variant) => {
    if (refVariant === "secondary") {
      return css`
        background-color: transparent;
        border: 1px solid ${darken(theme.palette.background.default, 1)};
        transition: transform 0.3s ease-in-out;

        :disabled {
          background-color: ${theme.palette.action.hover};
          border-color: ${theme.palette.action.hover};
        }

        :hover:not(:disabled) {
          // transform: scale(1.05);
        }

        :active:not(:disabled) {
          transform: scale(1);
        }
      `
    }

    if (refVariant === "tertiary") {
      return css`
        background-color: transparent;
        border-color: ${theme.palette.divider};

        :disabled {
          background-color: transparent;
          border-color: ${theme.palette.action.selected};
        }

        :hover:not(:disabled) {
          background-color: ${theme.palette.action.selected};
          border-color: ${theme.palette.action.selected};
        }

        :active:not(:disabled) {
          transform: scale(1);
        }
      `
    }

    if (refVariant === "text") {
      return css`
        background-color: transparent;
        color: ${theme.palette.action.hover};

        :hover:not(:disabled) {
          color: ${theme.palette.action.active};
        }

        :active:not(:disabled) {
          transform: scale(1);
        }
      `
    }

    // Primary variant
    return css`
      background-color: ${theme.palette.text.primary};
      border-color: ${theme.palette.text.primary};
      color: ${theme.palette.background.paper};
      transition: transform 0.3s ease-in-out;

      :disabled {
        background-color: ${theme.palette.action.hover};
        border-color: ${theme.palette.action.hover};
      }

      :hover:not(:disabled) {
      }

      :active:not(:disabled) {
        background-color: ${theme.palette.button.dark};
        border-color: ${theme.palette.button.dark};
      }
    `
  }

  return {
    getButton: ({ disabled }: { disabled: boolean }) => css`
      border-radius: 24px;
      padding: ${small ? theme.spacing(1.2, 2.5) : theme.spacing(1, 3)};
      border: 1px solid transparent;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.palette.text.primary};
      transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;

      :disabled {
        color: ${theme.palette.action.disabled};
      }

      ${!disabled &&
      css`
        cursor: pointer;
      `}

      ${fullWidth &&
      css`
        width: 100%;
      `};

      ${getButtonVariantCss(variant)};
    `,
    loadingIcon: css`
      margin-right: ${theme.spacing(2)};
      margin-top: -3px;
      margin-bottom: -3px;
    `,
    label: css`
      display: inline-flex;
      font-weight: 400;
      color: inherit;
      opacity: 1;
    `,
    link: css`
      font-weight: 400;
    `,
  }
}

export default useStyles
