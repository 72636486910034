import { ChainIds } from "types"
import { EXPLORER_URLS } from "./constants"

export interface Config {
  chainId: ChainIds
  apiUrl: string
  explorerUrl: string
}

const chainId: ChainIds = ChainIds.MAINNET

const apiUrl = "/api"
const explorerUrl = EXPLORER_URLS[chainId]

export const IPCOMPLY_ENDPOINT = "https://ondo.finance/api/ipcomply"
export const WALLETSCREEN_ENDPOINT = "https://ondo.finance/api/walletscreen"

const config: Config = {
  chainId,
  apiUrl,
  explorerUrl,
}

export default config

export const PROD_FLUX_MAINNET = "https://subgraph.satsuma-prod.com/115782bff0b0/ondo/flux-ethereum-prod/api"
