/** @jsxImportSource @emotion/react */
import { Box, Button, Stack } from "@mui/material"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import Menu from "@mui/material/Menu"
import Typography from "@mui/material/Typography"
import Image from "next/image"
import React, { FC, useContext, useState } from "react"
import { useTranslation } from "translation"

import LogoNoText from "assets/img/fluxLogoPure.svg"
import LogoText from "assets/img/fluxLogoWithText.svg"
import burger from "assets/img/icons/burger.svg"

import { Icon } from "../../Icon"
import ConnectButton from "../ConnectButton"
import { FLUX_DISCORD_URL, FLUX_MEDIUM_URL, FLUX_TWITTER_URL } from "../../../constants"
import { Toolbar } from "../Toolbar"
import { menuItems } from "../constants"
import Link from "./Link"
import { useStyles } from "./styles"
import { AuthContext } from "context/AuthContext"

const NewBadge = () => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "12px",
        fontWeight: "600",
        background: "#C4D5BE",
        borderRadius: "6px",
        padding: "0px 4px",
        height: "20px",
      }}
    >
      {t("sidebar.newBadge")}
    </Box>
  )
}

const SidebarUi: FC = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const open = Boolean(anchorEl)
  const { t } = useTranslation()
  const styles = useStyles()
  const { address } = useContext(AuthContext)

  const openMenu = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget as any)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const socialLinks = (
    <Stack direction="row" spacing={1} mx={{ lg: 3, xs: 0.5 }} my={{ lg: 3, xs: 2 }}>
      <Button css={styles.socialButton} href={FLUX_TWITTER_URL} target="_blank">
        <Icon name="twitter" size="1.4em" />
      </Button>
      <Button css={styles.socialButton} href={FLUX_DISCORD_URL} target="_blank">
        <Icon name="discord" size="1.4em" />
      </Button>
      <Button css={styles.socialButton} href={FLUX_MEDIUM_URL} target="_blank">
        <Icon name="blog" size="1.4em" />
      </Button>
    </Stack>
  )

  return (
    <>
      {/* Desktop and tablet menu */}
      <Box css={styles.spacer} />
      <Box css={styles.drawer}>
        <div css={styles.drawerContent}>
          <Toolbar css={styles.toolbar}>
            <Link href="/">
              <div css={styles.headerLink}>
                <Image src={LogoText} alt="" css={styles.logo} className="icon" />
                <Image src={LogoNoText} alt="" css={styles.logoClosed} className="icon" />
              </div>
            </Link>
          </Toolbar>

          <List css={styles.desktopList}>
            {menuItems.map((menuItem) => (
              <ListItemButton key={menuItem.i18nKey} component="li" css={styles.listItem} disableRipple>
                <Link href={menuItem.href}>
                  <div className="left-border" />

                  <ListItemIcon css={styles.listItemIcon}>
                    <Icon name={menuItem.icon} />
                  </ListItemIcon>

                  <Typography variant="body2" css={styles.listItemText} mr={1}>
                    {t(menuItem.i18nKey)}
                  </Typography>

                  {menuItem.isNew && <NewBadge />}
                </Link>
              </ListItemButton>
            ))}
          </List>
        </div>
        <div style={{ flex: 1 }} />
        {socialLinks}
      </Box>

      {/* Mobile menu */}
      <div css={styles.mobileMenuBox}>
        <div css={styles.flexRow} style={{ height: "40px" }}>
          <div css={styles.headerLink}>
            <Link href="/">
              <Image src={LogoNoText} css={styles.mobileLogo} alt="" className="icon" />
            </Link>
          </div>
          <ConnectButton variant="primary" css={styles.mobileConnectButton} />
          <button type="button" onClick={openMenu as any} css={styles.actionButton}>
            <Image src={burger} alt="menu" width={32} height={32} id="burger" />
          </button>
        </div>

        <Menu
          css={styles.mobileMenu}
          className="mobile-menu"
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={closeMenu}
          transitionDuration={0}
          marginThreshold={0}
          TransitionProps={{ style: { transition: "background 0.3s ease-in-out" } }}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 0, left: 0 }}
        >
          <div css={styles.flexRow} style={{ height: "40px" }}>
            <div css={styles.headerLink}>
              <Link href="/">
                <Image src={LogoNoText} css={styles.mobileLogo} alt="" className="icon" />
              </Link>
            </div>
            {/* <ConnectButton variant="primary" css={styles.mobileConnectButton} /> */}
            <button type="button" onClick={closeMenu} css={styles.actionButton}>
              <Icon name="close" css={styles.burger} />
            </button>
          </div>

          <List css={styles.mobileList}>
            {menuItems.map(({ href, icon, i18nKey, isNew }) => (
              <ListItemButton key={i18nKey} component="li" css={[styles.listItem, styles.mobileListItem]} disableRipple>
                <Link onClick={closeMenu} href={href}>
                  <div css={styles.mobileLabel}>
                    <ListItemIcon css={styles.listItemIcon}>
                      <Icon name={icon} />
                    </ListItemIcon>

                    <Typography
                      variant="body2"
                      component="span"
                      css={[styles.listItemText, styles.mobileListItemText]}
                      mr={1}
                    >
                      {t(i18nKey)}
                    </Typography>

                    {isNew && <NewBadge />}
                  </div>

                  <Icon name="arrowRight" css={styles.mobileArrow} />
                </Link>
              </ListItemButton>
            ))}
            {socialLinks}
          </List>
        </Menu>
      </div>
    </>
  )
}

export default SidebarUi
