/** @jsxImportSource @emotion/react */
import { Box, Link, Stack, Typography } from "@mui/material"
import React, { FC, useState } from "react"

import { PrimaryButton, SecondaryButton } from "components/Button"
import { LabelledCheckbox } from "components/LabelledCheckbox"

import { useStyles } from "./styles"

export interface ComplianceProps {
  isRestricted: boolean
  onAccept: any
}

const Compliance: FC<ComplianceProps> = ({ isRestricted, onAccept }: ComplianceProps) => {
  const styles = useStyles()

  const [isChecked, setIsChecked] = useState<boolean>(false)

  return (
    <div css={styles.container}>
      <Stack spacing={2}>
        <Typography variant="body1" sx={{ mb: 4 }}>
          Flux Finance does not permit access to users located in Afghanistan, Belarus, Crimea, Cuba, Donetsk, Iran,
          Luhansk, North Korea, Russia, Syria, and Venezuela (“Prohibited Jurisdictions”), based on applicable laws and
          regulations and other criteria. In using Flux Finance, you confirm that you are not located in, incorporated
          or otherwise organized in, or a citizen or resident of, a Prohibited Jurisdiction. Flux Finance routinely
          reviews and makes updates to its Prohibited Jurisdictions list as appropriate.
        </Typography>
        <Box>
          <LabelledCheckbox disabled={isRestricted} setChecked={(c) => setIsChecked(c)} testId="accept-terms-checkbox">
            I confirm that I have read, understand and accept the{" "}
            <Link
              variant="body1"
              href="https://docs.fluxfinance.com/legal/terms-of-service"
              target="__blank"
              color="secondary"
              underline="hover"
            >
              Terms of Use
            </Link>
            {" and the "}
            <Link
              variant="body1"
              href="https://docs.fluxfinance.com/legal/privacy-policy"
              target="__blank"
              color="secondary"
              underline="hover"
            >
              Risk Statement
            </Link>
          </LabelledCheckbox>
        </Box>
        <Stack style={{ marginTop: "24px" }} direction="row" spacing={2} alignItems="center">
          <PrimaryButton fullWidth disabled={isRestricted || !isChecked} onClick={onAccept}>
            Enter App
          </PrimaryButton>
        </Stack>
      </Stack>
    </div>
  )
}

export default Compliance
