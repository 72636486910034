import BigNumber from "bignumber.js"
import { Asset, Token } from "types"
import { formatTokensToReadableValue } from "./formatting"

export const calculateCollateralValue = ({
  token,
  tokenPriceTokens,
  collateralFactor,
  amountWei,
}: {
  token: Token
  tokenPriceTokens: Asset["tokenPrice"]
  collateralFactor: Asset["collateralFactor"]
  amountWei: BigNumber
}) => {
  const collateralValue = convertWeiToTokens({ valueWei: amountWei, token }).times(tokenPriceTokens)

  return collateralValue
}

export const calculateDailyEarningsCents = (yearlyEarningsCents: BigNumber) => yearlyEarningsCents.dividedBy(365)

export const calculateNetApy = ({
  supplyBalanceCents,
  yearlyEarningsCents,
}: {
  supplyBalanceCents: BigNumber
  yearlyEarningsCents: BigNumber
}) => {
  // Calculate net APY as a percentage of supply balance, based on yearly interests
  let netApyPercentage: number | undefined
  if (supplyBalanceCents?.isEqualTo(0)) {
    netApyPercentage = 0
  } else if (supplyBalanceCents && yearlyEarningsCents) {
    netApyPercentage = +yearlyEarningsCents.dividedBy(supplyBalanceCents).multipliedBy(100).toFixed(2)
  }
  return netApyPercentage
}

export interface CalculatePercentageInput {
  numerator: number
  denominator: number
}

export const calculatePercentage = ({ numerator, denominator }: CalculatePercentageInput) => {
  if (denominator === 0) {
    return 0
  }

  return (numerator * 100) / denominator
}

export const calculateYearlyEarningsForAsset = ({ asset }: { asset: Asset }) => {
  const assetBorrowBalanceCents = asset.borrowBalance.multipliedBy(asset.tokenPrice).multipliedBy(100)
  const assetSupplyBalanceCents = asset.supplyBalance.multipliedBy(asset.tokenPrice).multipliedBy(100)

  const supplyYearlyEarningsCents = assetSupplyBalanceCents.multipliedBy(asset.supplyApy.dividedBy(100))

  // TODO: Uncomment this when we have a way to get the underlying APY (OUSG APY)
  // if (asset.underlyingApy?.gt(0)) {
  //   supplyYearlyEarningsCents = supplyYearlyEarningsCents.plus(
  //     asset.supplyBalance.multipliedBy(asset.underlyingApy.dividedBy(100)),
  //   );
  // }

  // Note that borrowYearlyInterests will always be negative (or 0), since
  // the borrow APY is expressed with a negative percentage)
  const borrowYearlyInterestsCents = assetBorrowBalanceCents.multipliedBy(
    asset.borrowApy.dividedBy(100).multipliedBy(-1),
  )

  return supplyYearlyEarningsCents.plus(borrowYearlyInterestsCents)
}

export const calculateYearlyEarningsForAssets = ({ assets, inTokens }: { assets: Asset[]; inTokens?: boolean }) => {
  // We use the yearly earnings to calculate the daily earnings the net APY
  let yearlyEarnings: BigNumber | undefined

  assets.forEach((asset) => {
    if (!yearlyEarnings) yearlyEarnings = new BigNumber(0)

    const assetYearlyEarnings = calculateYearlyEarningsForAsset({
      asset,
    })
    if (inTokens) {
      yearlyEarnings = yearlyEarnings.plus(assetYearlyEarnings.dividedBy(100).dividedBy(asset.tokenPrice))
    } else yearlyEarnings = yearlyEarnings.plus(assetYearlyEarnings)
  })

  return yearlyEarnings
}

export interface ConvertWeiToTokensInput<T extends boolean | undefined = false> {
  valueWei: BigNumber
  token: Token
  returnInReadableFormat?: T
  minimizeDecimals?: boolean
  addSymbol?: boolean
  shortenLargeValue?: boolean
}

export type ConvertWeiToTokensOutput<T> = T extends true ? string : BigNumber

export function convertWeiToTokens<T extends boolean | undefined = false>({
  valueWei,
  token,
  returnInReadableFormat = false,
  minimizeDecimals = false,
  addSymbol = true,
  shortenLargeValue = false,
}: ConvertWeiToTokensInput<T>): ConvertWeiToTokensOutput<T> {
  const valueTokens = valueWei.dividedBy(new BigNumber(10).pow(token.decimals)).decimalPlaces(token.decimals)

  return (
    returnInReadableFormat
      ? formatTokensToReadableValue({
          value: valueTokens,
          token,
          minimizeDecimals,
          addSymbol,
          shortenLargeValue,
        })
      : valueTokens
  ) as ConvertWeiToTokensOutput<T>
}

export const convertTokensToWei = ({ value, token }: { value: BigNumber; token: Token }) =>
  value.multipliedBy(new BigNumber(10).pow(token.decimals))

export const convertPercentageFromSmartContract = (percentageFromSmartContract: string | BigNumber) =>
  new BigNumber(percentageFromSmartContract)
    .dividedBy(new BigNumber(10).pow(18))
    // Convert to percentage
    .multipliedBy(100)
    .toNumber()

export function calculateAPY(interestAccruedPerInterval: number, intervalsPerYear: number): number {
  return Math.pow(1 + interestAccruedPerInterval, intervalsPerYear) - 1
}
