import { css } from "@emotion/react"
import { useTheme } from "@mui/material"

import imgMark from "assets/img/mark.svg"
import { borderRadius } from "muiTheme"

export const SELECTED_MENU_ITEM_CLASSNAME = "SELECTED_MENU_ITEM_CLASSNAME"
export const useStyles = () => {
  const theme = useTheme()

  return {
    root: ({ isOpen }: { isOpen: boolean }) => css`
      background-color: ${theme.palette.background.paper};
      border-radius: ${borderRadius.small}px;
      border: 1px solid ${isOpen ? theme.palette.interactive.primary : "transparent"};
      width: 100%;
      > div {
        padding: ${theme.spacing(3, 4)};
      }
    `,
    getArrowIcon: ({ isMenuOpened }: { isMenuOpened: boolean }) => css`
      position: absolute;
      right: ${theme.spacing(4)};
      width: ${theme.spacing(3)};
      transition: transform 0.3s;
      transform: rotate(${isMenuOpened ? "180deg" : "0"});
      pointer-events: none;
    `,
    menuItem: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: ${theme.palette.text.primary};
      background-color: ${theme.palette.background.paper};

      &:active,
      &:hover,
      &:focus {
        background-color: ${theme.palette.action.hover};

        ${theme.breakpoints.down("sm")} {
          background-color: ${theme.palette.action.hover};
        }
      }

      &.${SELECTED_MENU_ITEM_CLASSNAME} {
        background-color: ${theme.palette.action.hover} !important;

        ${theme.breakpoints.down("sm")} {
          background-color: ${theme.palette.action.hover}!important;
        }
      }
      ${theme.breakpoints.down("sm")} {
        padding-top: 0;
      }
    `,
    mobileHeader: css`
      display: none;

      ${theme.breakpoints.down("sm")} {
        padding: ${theme.spacing(6, 4)};
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        top: 0;
        background-color: ${theme.palette.background.paper};
        z-index: 1;
        border-bottom: 1px solid ${theme.palette.action.hover};
      }
    `,
    closeMenuButton: css`
      position: absolute;
      right: 0;
    `,

    /* styles passed as MenuProps are not recognized if we pass them as emotion SerializedStyles */
    menuWrapper: {
      backgroundColor: theme.palette.background.paper,
      padding: 0,
      borderRadius: `${borderRadius.medium}px`,
      marginTop: theme.spacing(1),
      border: `1px solid ${theme.palette.divider}`,

      [theme.breakpoints.down("sm")]: {
        minWidth: "calc(100vw)",
        height: "100%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: `${borderRadius.large}px`,
      },
    },
  }
}
