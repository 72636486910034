/** @jsxImportSource @emotion/react */
import { Typography } from "@mui/material"
import { useRouter } from "next/router"
import React, { ReactElement, useEffect, useState } from "react"

import { TertiaryButton } from "../Button"
import useStyles from "./styles"

export type TabContent = {
  title: string
  content: ReactElement
}

export interface TabsProps {
  tabsContent: TabContent[]
  componentTitle?: string
  onTabChange?: (newIndex: number) => void
  className?: string
}

export const Tabs = ({ tabsContent, onTabChange, className, componentTitle }: TabsProps) => {
  const styles = useStyles()
  const router = useRouter()
  const activeTabIndex = (router.query?.tab as string) ?? "0"

  const handleChange = (index: number) => {
    router.push({ query: { ...router.query, tab: index.toString() } })
    // Only call onTabChange callback if tab clicked isn't currently active
    if (index.toString() !== activeTabIndex && onTabChange) {
      onTabChange(index)
    }
  }

  return (
    <>
      <div
        css={styles.getContainer({
          hasTitle: !!componentTitle,
        })}
        className={className}
      >
        {componentTitle && (
          <div css={[styles.headerTitle]}>
            <Typography variant="h6">{componentTitle}</Typography>
          </div>
        )}

        <div css={styles.getButtonsContainer({ fullWidth: !componentTitle })}>
          {tabsContent.map(({ title }, index) => (
            <TertiaryButton
              key={title}
              onClick={() => handleChange(index)}
              css={styles.getButton({
                active: index.toString() === activeTabIndex,
                last: index === tabsContent.length - 1,
                fullWidth: !componentTitle,
              })}
            >
              {title}
            </TertiaryButton>
          ))}
        </div>
      </div>

      {tabsContent[+activeTabIndex].content}
    </>
  )
}
