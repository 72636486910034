import { css } from "@emotion/react"
import { useTheme } from "@mui/material"
import { borderRadius } from "muiTheme"

export const useStyles = () => {
  const theme = useTheme()

  return {
    getContainer: ({ hasTitle }: { hasTitle: boolean }) => css`
      display: flex;
      padding: ${theme.spacing(0, 4)};
      width: 100%;

      ${hasTitle &&
      css`
        align-items: center;
      `}
    `,
    headerTitle: css`
      padding-right: ${theme.spacing(4)};
      text-align: left;

      ${theme.breakpoints.down("sm")} {
        width: 100%;
        padding-right: 0;
      }
    `,
    getButtonsContainer: ({ fullWidth }: { fullWidth: boolean }) => css`
      display: flex;
      align-items: center;

      ${theme.breakpoints.down("sm")} {
        width: 100%;
      }

      ${fullWidth &&
      css`
        width: 100%;
      `}
    `,
    getButton: ({ active, last, fullWidth }: { active: boolean; last: boolean; fullWidth: boolean }) => css`
      border: 0;
      background-color: ${theme.palette.action.hover};
      border-radius: ${borderRadius.medium};
      opacity: ${active ? 1 : 0.5};
      :hover:not(:disabled),
      :active:not(:disabled) {
        background-color: ${theme.palette.action.hover};
        border-color: ${theme.palette.action.hover};
        opacity: 1;
      }

      ${fullWidth &&
      css`
        flex: 1;
      `}

      ${!last &&
      css`
        margin-right: ${theme.spacing(4)};
      `};

      ${!active &&
      css`
        background-color: transparent;
        border-color: transparent;

        :not(:hover, :active) {
          color: ${theme.palette.text.primary};
        }

        :hover {
          color: ${theme.palette.text.primary};
        }
      `};

      ${theme.breakpoints.down("sm")} {
        flex: 1;
      }
    `,
  }
}

export default useStyles
