import BigNumber from "bignumber.js"
import { STABLECOINS, PLACEHOLDER_KEY } from "../constants"
import { Token } from "types"

export const formatCentsToReadableValue = ({
  value,
  shortenLargeValue = false,
  decimals = 2,
  showDust = true,
}: {
  value: number | BigNumber | undefined
  shortenLargeValue?: boolean
  decimals?: number
  showDust?: boolean
}) => {
  if (value === undefined) {
    return PLACEHOLDER_KEY
  }

  const wrappedValueDollars = new BigNumber(value).dividedBy(100)

  if (showDust && wrappedValueDollars.gt(0) && wrappedValueDollars.lt(0.01)) return "Dust"

  if (!shortenLargeValue) {
    return `$${wrappedValueDollars.toFormat(decimals)}`
  }

  // Shorten value
  const shortenedValue = shortenTokensWithSuffix(wrappedValueDollars, decimals)
  return `$${shortenedValue}`
}

export const formatTokensToReadableValue = ({
  value,
  token,
  minimizeDecimals = false,
  shortenLargeValue = false,
  addSymbol = true,
  showDust = true,
}: {
  value: BigNumber | undefined
  token: Token
  minimizeDecimals?: boolean
  shortenLargeValue?: boolean
  addSymbol?: boolean
  showDust?: boolean
}) => {
  if (value === undefined) {
    return PLACEHOLDER_KEY
  }

  if (showDust && value.gt(0) && value.lt(0.01) && minimizeDecimals) return "Dust"

  let decimalPlaces
  if (STABLECOINS.includes(token.symbol)) {
    decimalPlaces = 2
  } else if (minimizeDecimals) {
    decimalPlaces = 8
  } else {
    decimalPlaces = token.decimals
  }

  let symbolPlacement = ""
  if (addSymbol) {
    symbolPlacement = ` ${token.symbol}`
  }

  if (shortenLargeValue) {
    return `${shortenTokensWithSuffix(value)}${symbolPlacement}`
  }

  return `${value.dp(decimalPlaces).toFormat()}${symbolPlacement}`
}

export const formatPercentage = (value: string | number | BigNumber) => {
  const valueBn = new BigNumber(value)

  if (valueBn.absoluteValue().isGreaterThanOrEqualTo(100000000)) {
    return +valueBn.toExponential(2)
  }

  return +valueBn.dp(2).toFixed()
}

export const formatToReadablePercentage = (value: number | string | BigNumber | undefined) => {
  if (value === undefined) {
    return PLACEHOLDER_KEY
  }

  return `${formatPercentage(value)}%`
}

const ONE_BILLION = 1000000000
const ONE_MILLION = 1000000
const ONE_THOUSAND = 1000
export const shortenTokensWithSuffix = (orig_value: BigNumber, dp?: number) => {
  const decimals = dp === undefined ? 2 : dp
  const negative = orig_value.isNegative()
  const value = orig_value.absoluteValue()

  let shortenedValue = value.toFixed(decimals)
  if (value.isGreaterThanOrEqualTo(ONE_BILLION)) {
    shortenedValue = `${value.dividedBy(ONE_BILLION).dp(decimals).toFormat()}B`
  } else if (value.isGreaterThanOrEqualTo(ONE_MILLION)) {
    shortenedValue = `${value.dividedBy(ONE_MILLION).dp(decimals).toFormat()}M`
  } else if (value.isGreaterThanOrEqualTo(ONE_THOUSAND)) {
    shortenedValue = `${value.dividedBy(ONE_THOUSAND).dp(decimals).toFormat()}K`
  }
  return negative ? `-${shortenedValue}` : shortenedValue
}
