/** @jsxImportSource @emotion/react */
import NextLink from "next/link"
import { useRouter } from "next/router"
import React, { FC } from "react"

import { MenuItem } from "../../types"

export interface LinkProps {
  href: MenuItem["href"]
  onClick?: () => void
  isMobile?: boolean
  children: React.ReactNode
}

export const Link: FC<LinkProps> = ({ children, onClick, href, isMobile = false }) => {
  const { pathname } = useRouter()
  if (href[0] === "/") {
    const activeClassName = isMobile ? "active-mobile-menu-item" : "active-menu-item"
    const active = href === pathname

    return (
      <NextLink legacyBehavior href={href} onClick={onClick}>
        <a className={active ? activeClassName : undefined}>{children}</a>
      </NextLink>
    )
  }

  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}

export default Link
