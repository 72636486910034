/** @jsxImportSource @emotion/react */
import Box from "@mui/material/Box"
import MaterialSlider from "@mui/material/Slider"
import { SliderTypeMap } from "@mui/material/Slider/Slider"
import { PALETTE } from "muiTheme"
import { Tooltip, TooltipProps } from "../Tooltip"
import { useStyles } from "./styles"

export interface ProgressBarProps {
  value: number
  secondaryValue?: number
  mark?: number
  step: number
  ariaLabel: string
  min: number
  max: number
  trackTooltip?: TooltipProps["title"]
  markTooltip?: TooltipProps["title"]
  className?: string
  tooltipPlacement?: TooltipProps["placement"]
  successColor?: string
}

export const ProgressBar = ({
  value,
  secondaryValue,
  mark,
  step,
  ariaLabel,
  min,
  max,
  trackTooltip,
  markTooltip,
  className,
  tooltipPlacement = "top",
  successColor = PALETTE.interactive.success,
}: ProgressBarProps) => {
  const safeValue = value < max ? value : max

  const marks = mark ? [{ value: mark }] : undefined
  const styles = useStyles({
    over: mark ? safeValue > mark : false,
    secondaryOver: mark ? !!(secondaryValue && secondaryValue > mark) : false,
    successColor,
  })

  const renderMark = (props?: NonNullable<SliderTypeMap["props"]["componentsProps"]>["mark"] & any) => (
    <Box
      component="span"
      style={props?.style}
      className={props?.className}
      css={[styles.mark, markTooltip ? styles.hasTooltip : undefined]}
    >
      {markTooltip && (
        <Tooltip placement={tooltipPlacement} title={markTooltip}>
          <span css={styles.tooltipHelper}>.</span>
        </Tooltip>
      )}
    </Box>
  )

  const renderTrack = (props?: NonNullable<SliderTypeMap["props"]["componentsProps"]>["track"] & any) => {
    const primaryRail = (
      <Box style={props?.style} css={[styles.trackWrapper, trackTooltip ? styles.hasTooltip : undefined]}>
        {trackTooltip ? (
          <Tooltip placement={tooltipPlacement} title={trackTooltip}>
            <Box className={props?.className} />
          </Tooltip>
        ) : (
          <Box className={props?.className} />
        )}
      </Box>
    )

    return (
      <>
        {primaryRail}

        {secondaryValue !== undefined && (
          <Box css={styles.secondaryRail(secondaryValue < max ? secondaryValue : max)} className={props?.className} />
        )}
      </>
    )
  }

  return (
    <MaterialSlider
      className={className}
      css={styles.slider}
      components={{
        Thumb: () => null,
        Mark: mark ? renderMark : undefined,
        Track: renderTrack,
      }}
      value={safeValue}
      marks={marks}
      step={step}
      aria-label={ariaLabel}
      min={min}
      max={max}
      size="medium"
      disabled
    />
  )
}
