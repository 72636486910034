import { useContext } from "react"
import { QueryObserverOptions, useQuery } from "react-query"

import { DEFAULT_REFETCH_INTERVAL_MS, FunctionKey } from "../../constants"
import { AuthContext } from "context/AuthContext"
import getMarkets, { GetMarketsOutput } from "."

type Options = QueryObserverOptions<
  GetMarketsOutput,
  Error,
  GetMarketsOutput,
  GetMarketsOutput,
  FunctionKey.GET_MARKETS
>

const useGetMarkets = (options?: Options) => {
  const { chain } = useContext(AuthContext)
  return useQuery(FunctionKey.GET_MARKETS, () => getMarkets(chain?.id), {
    refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
    ...options,
  })
}

export default useGetMarkets
