import BigNumber from "bignumber.js"
import { Address } from "viem"

export enum ChainIds {
  "MAINNET" = 1,
  "BSC" = 56,
}

export interface Asset {
  token: Token
  tokenPrice: BigNumber
  borrowBalance: BigNumber
  walletBalance: BigNumber
  borrowApy: BigNumber
  borrowCaps: BigNumber
  supplyApy: BigNumber
  collateralFactor: BigNumber
  collateral: boolean
  supplyBalance: BigNumber
  percentOfLimit: string
  // treasuryBalance: BigNumber;
  totalBorrowsUsd: number
  totalSupplyUsd: number
  totalSupplyTokens: BigNumber
  totalBorrowTokens: BigNumber
  utilizationRate: number
}

export interface Token {
  id: string // TODO: remove (related to VEN-723)
  symbol: Uppercase<string>
  decimals: number
  address: Address
  asset: string
  isNative?: boolean
  name: string
}

export interface Setting {
  marketType?: string // 'supply'
  pendingInfo: {
    type: string // 'Borrow'
    status: boolean
    symbol: string
    amount: string | number
  }
}

export interface DescriptionV2 {
  version: "v2"
  title: string
  description: string
  forDescription: string
  againstDescription: string
  abstainDescription: string
}

export interface DescriptionV1 {
  version: "v1"
  title: string
  description: string
  forDescription?: undefined
  againstDescription?: undefined
  abstainDescription?: undefined
}

export interface Pool {
  poolId: BigNumber
  stakedToken: string
  rewardToken: string
  userStakedAmount: BigNumber
  pendingReward: BigNumber
  lockPeriodSecond: BigNumber
  apr: BigNumber
  totalStaked: BigNumber
  dailyEmission: BigNumber
}

export interface VoteTransaction {
  support: boolean
  type: "vote"
  blockTimestamp: number
  amount: string
  to: string
  votes: string
}

export interface Market {
  id: string
  address: string
  borrowApy: BigNumber
  borrowCaps: string
  borrowRatePerBlock: string
  borrowerCount: number
  collateralFactor: number
  exchangeRate: string
  lastCalculatedBlockNumber: number
  name: string
  reserveFactor: string
  exchangeRateHumanReadable: number
  supplierCount: number
  supplyApy: BigNumber
  supplyRatePerBlock: string
  symbol: string
  tokenPrice: BigNumber
  totalBorrows2: string
  totalBorrowsUsd: number
  totalSupply: string
  totalSupply2: string
  totalSupplyUsd: number
  underlyingAddress: string
  underlyingDecimal: number
  underlyingName: string
  underlyingPrice: string
  underlyingSymbol: string
}

export interface MarketSnapshot {
  asset: string
  blockNumber: number
  blockTimestamp: number
  borrowApy: number
  createdAt: string
  exchangeRate: string
  id: string
  priceUSD: string
  supplyApy: number
  totalBorrows: string
  totalSupply: string
  updatedAt: string
}

export type TransactionEvent = "Mint" | "Transfer" | "Borrow" | "RepayBorrow" | "Redeem" | "LiquidateBorrow"

export enum TransactionCategory {
  ftoken = "ftoken",
}

export interface Transaction {
  id: number
  amountWei: BigNumber
  blockNumber: number
  category: TransactionCategory
  createdAt: Date
  event: TransactionEvent
  from: string
  to: string
  timestamp: string | null
  transactionHash: string
  updatedAt: Date
  fTokenAddress: string
}
