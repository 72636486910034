import { css } from "@emotion/react"
import { useTheme } from "@mui/material"

import { FONTS } from "muiTheme"

export const useStyles = () => {
  const theme = useTheme()
  return {
    btnClose: css`
      position: absolute;
      padding: 0;
      right: ${theme.spacing(4)};
      top: ${theme.spacing(4)};
    `,
    /* extra padding for placing the close button */
    noticeContainer: css`
      padding-right: ${theme.spacing(10)};
    `,
  }
}

export const customToastGlobalStyles = {
  ".Toastify__toast-container": {
    maxWidth: "25rem",
    width: "100%",
    top: "13px !important",
    right: "24px !important",
    padding: "0 !important",
    ".Toastify__toast": {
      padding: "0 !important",
      fontFamily: FONTS.primary,
      backgroundColor: "transparent",
      boxShadow: "none",
      marginBottom: 0,
    },
  },
}
