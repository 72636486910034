import { FLUX_BOUNTIES_URL, FLUX_DAO_URL, FLUX_DOCS_URL, FLUX_FORUM_URL } from "../../constants"
import { MenuItem } from "./types"

export const menuItems: MenuItem[] = [
  {
    href: "/lend",
    // Translation key: do not remove this comment
    // t('layout.menuItems.dashboard')
    i18nKey: "layout.menuItems.lend",
    i18nTitleKey: "layout.menuItems.lend",
    icon: "lend",
  },
  {
    href: "/borrow",
    // Translation key: do not remove this comment
    // t('layout.menuItems.dashboard')
    i18nKey: "layout.menuItems.borrow",
    i18nTitleKey: "layout.menuItems.borrow",
    icon: "borrow",
  },
  {
    href: "/markets",
    // Translation key: do not remove this comment
    // t('layout.menuItems.markets')
    i18nKey: "layout.menuItems.markets",
    i18nTitleKey: "layout.menuItems.markets",
    icon: "market",
  },
  {
    href: "/history",
    // Translation key: do not remove this comment
    // t('layout.menuItems.history')
    i18nKey: "layout.menuItems.history",
    i18nTitleKey: "layout.menuItems.history",
    icon: "history",
  },
  {
    href: FLUX_FORUM_URL,
    // Translation key: do not remove this comment
    // t('layout.menuItems.history')
    i18nKey: "layout.menuItems.community",
    i18nTitleKey: "layout.menuItems.community",
    icon: "community",
  },
  {
    href: FLUX_DAO_URL,
    // Translation key: do not remove this comment
    // t('layout.menuItems.history')
    i18nKey: "layout.menuItems.vote",
    i18nTitleKey: "layout.menuItems.vote",
    icon: "vote",
  },
  {
    href: FLUX_DOCS_URL,
    // Translation key: do not remove this comment
    // t('layout.menuItems.history')
    i18nKey: "layout.menuItems.docs",
    i18nTitleKey: "layout.menuItems.docs",
    icon: "docs",
  },
  {
    href: FLUX_BOUNTIES_URL,
    // Translation key: do not remove this comment
    // t('layout.menuItems.history')
    i18nKey: "Bug Bounty",
    i18nTitleKey: "Bug Bounty",
    icon: "bounties",
  },
]
